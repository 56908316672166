import { 
    faFileInvoiceDollar
    // faCheckDouble, 
    // faPlay, 
    // faTimes 
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, {useEffect,useState} from "react";
// import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import AdmUsrContent from "./views/adm-usr-content";
import AppointmentConfirm from "./views/appointment-confirm";
import AppointmentInAssists from "./views/appointment-in-assists";
import AppointmentModel from "./views/appointment-modal";
import InvoiceAppointment from "./views/invoice-appointment";
import UsrAppointment from "./views/usr-appointment";
import UsrContent from "./views/usr-content";
const styling = {
    container: {
        backgroundColor: '#eee',
        height: '100vh'
    },
    columns: {
        backgroundColor: '#fff',
        // height: '100vh',
        overflowY: 'auto',
        paddingLeft: '0px',
        paddingRight: '0px',
        borderRight: '1px solid #eee'
    },
    headerInfoLabel: {
        backgroundColor: '#fff',
        textAlign: 'center',
        border: '1px solid rgb(213 213 213)'
    }
}
const defaultData = {
    customers: [],
    products: [],
    myAppointments: 0,
    myConfirmAppointments: 0,
    myCancelAppointments: 0
}
const defaultUsrLogged = {
    id: 0,
    user_name: "",
    credential: "",
    email: "",
    phone: ""
}
const Appointment = () => {
    const [admPermission,setAdmPermission] = useState(false),
        [crtPermission,setCrtPermission] = useState(false),
        [appointments,setAppointments] = useState([]),
        /** Adm Appointments */
        [admWorkingAppointments,setAdmWorkingAppointments] = useState([]),
        [admAppointments,setAdmAppointments] = useState([]),
        [confirmPermission,setConfirmPermission] = useState(false),
        [assistsPermission,setAssistsPermission] = useState(false),

        [workingAppointments,setWorkingAppointments] = useState([]),
        [appointmentsBeforeFromDate,setAppointmentsBeforeFromDate] = useState([]),
        [usrData,setUsrData] = useState(defaultUsrLogged),
        [isLoading,setIsLoading] = useState(false),
        [crtNewAppointment,setCrtNewAppointment] = useState(false),
        [invoicing,setInvoicing] = useState(false),
        [mData,setMData] = useState(defaultData),
        [turn,setTurn] = useState([]),
        [appointmentFoInvoice,setAppointmentForInvoice] = useState(0),

        /** Reset data */
        [resetAppointmentConfirmation,setResetAppointmentConfirmation] = useState([]),
        [resetAppointmentAssists,setResetAppointmentAssists] = useState([]),
        
        /** View */
        [appointmentConfirmView,setAppointmentConfirmView] = useState(false),
        [appointmentAssistsView,setAppointmentAssistsView] = useState(false)
        ;
    useEffect(async()=>{
        setIsLoading(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'appointments',{token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
            data = await resource.data;
            if(data.sessionOut){
                // eslint-disable-next-line no-undef
                location.href=process.env.REACT_APP_PATH_APP;
                return;
            }
            let workingAppointments = data.workingAppointments;
            for(let i=0;i<workingAppointments.length;i++){
                workingAppointments[i].chooseIcon = <FontAwesomeIcon title="Cerrar y facturar turno" id={parseInt(workingAppointments[i].id)} icon={faFileInvoiceDollar} onClick={invoiceAppointment} style={{fontSize: '14px',fontWeight: 'bold',cursor: 'pointer'}} />
            }
            setAdmPermission(data.admPermission);
            setCrtPermission(data.crtPermission);
            setAppointments(data.appointments);
            setWorkingAppointments(workingAppointments);
            setAppointmentsBeforeFromDate(data.appointmentsBeforeFromDate);
            setIsLoading(false);
            setMData({
                ...mData,
                customers: data.customers,
                products: data.products,
                myAppointments: data.myAppointments,
                myConfirmAppointments: data.myConfirmAppointments,
                myCancelAppointments: data.myCancelAppointments,
                myCompletedAppointments: data.myCompletedAppointments
            });
            setUsrData(data.usr);
            setTurn(data.turn);
            /** ADM */
            setAdmAppointments(data.AdmAppointments);
            setAdmWorkingAppointments(data.AdmWorkingAppointments);
            setConfirmPermission(data.confirmPermission);
            setAssistsPermission(data.assistsPermission);
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    },[]);    
    const cancel = async(input) => {
        const id = input.currentTarget.getAttribute('id');
        Swal.fire({
            title: '¿Esta seguro de cancelar esta cita?',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'Eliminar'
        }).then((result)=>{
            if (result.isConfirmed) 
            {
                if(result.value){                    
                    cancelAppointment(id,result.value);                
                } else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: "Debe ingresar un comentario para la eliminación"
                    });
                }
            }
        });
    }
    const cancelAppointment = async(id,comment) => {
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'appointment/cancellation',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                data: {id: id, comment: comment}
            }),
            data = await resource.data;
            if(!data.status){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.message
                });
                return;
            }
            Swal.fire({
                icon: 'success',
                title: 'Operación Exitosa',
                text: data.message
            }).then(()=>{
                setAppointmentsBeforeFromDate(data.appointmentsBeforeFromDate);
                setResetAppointmentConfirmation(data.appointmentConfirmation);
                setResetAppointmentAssists(data.appointmentAssists);
                setTurn(data.turns);
            });
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const invoiceAppointment = (input) => {
        const id = input.currentTarget.getAttribute('id');
        setAppointmentForInvoice(id);
        setInvoicing(true);
    }
    const confirmUpdateDateAppointment = async(id,date) => {
        Swal.fire({
            title: '¿Esta seguro de actualizar la fecha de esta cita?',
            showCancelButton: true,
            confirmButtonText: 'Actualizar'
        }).then((result)=>{
            if (result.isConfirmed) 
            {
                updateDateAppointment(id,date);
            }
        });
    }
    const updateDateAppointment = async(id,date) => {
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'appointment/updating/date',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                data: {id: id, date: date}
            }),
            data = await resource.data;
            setAppointmentsBeforeFromDate(data.appointmentsBeforeFromDate);
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    return (
        <div style={{width: '100%',height: '100%',backgroundColor: '#eee'}}>
            <div className="container-fluid" style={styling.container}>
                <div className="row">
                    <div className="col-3" style={styling.columns}>                        
                        <UsrAppointment
                            crtPermission={crtPermission}
                            setCrtNewAppointment={setCrtNewAppointment}
                            usr={usrData}
                            turn={turn}
                            mData={mData}
                            appointmentsBeforeFromDate={appointmentsBeforeFromDate}
                            setAppointmentForInvoice={setAppointmentForInvoice}
                            setInvoicing={setInvoicing}
                            cancel={cancel}
                            setAppointmentConfirmView={setAppointmentConfirmView}
                            setAppointmentAssistsView={setAppointmentAssistsView}
                            confirmUpdateDateAppointment={confirmUpdateDateAppointment}
                            confirmPermission={confirmPermission}
                            assistsPermission={assistsPermission}
                        />                     
                    </div>
                    <div className="col-9" style={{...styling.columns,...{backgroundColor: '#eee'}}}>
                        {
                            appointmentConfirmView?
                                <AppointmentConfirm 
                                    styling={styling}
                                    cancel={cancel}
                                    resetAppointmentConfirmation={resetAppointmentConfirmation}
                                    setResetAppointmentConfirmation={setResetAppointmentConfirmation}
                                />
                            :
                            appointmentAssistsView?
                                <AppointmentInAssists 
                                    styling={styling}
                                    cancel={cancel}
                                    resetAppointmentAssists={resetAppointmentAssists}
                                />
                            :
                            admPermission?
                                <AdmUsrContent 
                                    styling={styling}
                                    appointments={admAppointments}
                                    workingAppointments={admWorkingAppointments}
                                    mData={mData}
                                    isLoading={isLoading}
                                />
                            :
                                <UsrContent 
                                    styling={styling}
                                    appointments={appointments}
                                    workingAppointments={workingAppointments}
                                    mData={mData}
                                    isLoading={isLoading}
                                />
                        }
                    </div>
                </div>
            </div>
            <AppointmentModel 
                show={crtNewAppointment}
                close={setCrtNewAppointment}
                mData={mData}
                setMData={setMData}
                setResetAppointmentConfirmation={setResetAppointmentConfirmation}
            />
            <InvoiceAppointment 
                show={invoicing}
                close={setInvoicing}
                appointment={appointmentFoInvoice}
                mData={mData}
                setTurn={setTurn}
            />
        </div>
    )
}
export default Appointment;