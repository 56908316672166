import React, {useState,useEffect} from "react";
import { faArrowLeft, faCalendar, faCheckCircle, faFileInvoiceDollar, /*faHome,*/ faPen, faPlus, faRefresh, faTimes, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Image, ProgressBar } from 'react-bootstrap';
import DateTimePicker from "react-datetime-picker";
const styling = {
    font: {
        fontSize: '12px',
        textTransform: 'initial',
        fontWeight: 'bold'
    },
    cFont: {
        fontSize: '9px',
        fontWeight: 'bold',
        textTransform: 'initial'
    },
    eFont: {
        fontSize: '9px',
        fontWeight: 'bold',
        textTransform: 'initial',
        marginLeft: '1rem'
    },
    fontBadge: {
        fontSize: '8px',
        fontWeight: 'bold',
        textTransform: 'initial'
    },
    td: {
        border: 'none'
    }
}
const UsrAppointment = (props) => {
    const [updatingAppointment, setUpdatingAppointment] = useState(0),
        [dateFilter,setDateFilter] = useState(new Date());
    let percent = 0;
    // eslint-disable-next-line react/prop-types
    if(parseInt(props.mData.myCompletedAppointments)>0&&parseInt(props.mData.myAppointments)>0){
        // eslint-disable-next-line react/prop-types
        percent = parseFloat((parseInt(props.mData.myCompletedAppointments)/parseInt(props.mData.myAppointments))*100).toFixed(2);
    }
    useEffect(()=>{
        setUpdatingAppointment(0);
    // eslint-disable-next-line react/prop-types
    },[props.appointmentsBeforeFromDate]);
    const invoiceAppointment = (input) => {
        const id = input.currentTarget.getAttribute('id');
        // eslint-disable-next-line react/prop-types
        props.setAppointmentForInvoice(id);
        // eslint-disable-next-line react/prop-types
        props.setInvoicing(true);
    }
    const setValueToUpdate = (input) => {
        const id = input.currentTarget.getAttribute('id');
        setUpdatingAppointment(id);
    }
    const updateAppointmentDate = () => {
        // eslint-disable-next-line react/prop-types
        props.confirmUpdateDateAppointment(updatingAppointment,dateFilter);
    }
    return (
        <table className="table">
            <thead>
                <tr>
                    <th>
                        <FontAwesomeIcon 
                            icon={faArrowLeft}
                            style={{cursor: 'pointer'}}
                            onClick={()=>{
                                // eslint-disable-next-line no-undef
                                location.href=process.env.REACT_APP_PATH_APP
                            }}
                            />
                    </th>
                    <th>MANEJADOR DE CITAS</th>
                    <th style={{textAlign: 'center',cursor: 'pointer'}} title="Refrescar información" onClick={()=>location.reload()}>
                        <FontAwesomeIcon 
                            icon={faRefresh}
                        /> <span style={{fontSize: "12px",textTransform: 'capitalize'}}>Actualizar</span>
                    </th>
                </tr>
            </thead>
            <tbody>
            <tr>
                <td colSpan={3} style={styling.td}>
                    {
                        // eslint-disable-next-line react/prop-types
                        props.usr.path_file?
                            <Image 
                                style={{width: '72px'}}
                                fluid={true}
                                roundedCircle={true}
                                thumbnail={true}
                                // eslint-disable-next-line no-undef, react/prop-types
                                src={process.env.REACT_APP_PATH_APP+props.usr.path_file}
                            />
                        :null
                    }                        
                    </td>
                </tr>
                <tr>
                    <td colSpan={3} style={{...styling.td,...styling.font,...{paddingTop: 10}}}>
                        {/* eslint-disable-next-line react/prop-types */}
                        {props.usr.user_name}
                    </td>
                </tr>
                <tr>
                    <td colSpan={3} style={{...styling.td,...styling.font,...{paddingTop: 0}}} className="text-muted">
                        {/* eslint-disable-next-line react/prop-types */}
                        {props.usr.credential}
                    </td>
                </tr>
                <tr>
                    <td colSpan={3} style={{...styling.td,...styling.font,...{paddingTop: 0}}} className="text-muted">
                        {/* eslint-disable-next-line react/prop-types */}
                        {props.usr.email}
                    </td>
                </tr>
                <tr>
                    <td colSpan={3} style={{...styling.td,...styling.font,...{paddingTop: 0}}} className="text-muted">
                        {/* eslint-disable-next-line react/prop-types */}
                        {props.usr.phone}
                    </td>
                </tr>                
                {
                    // eslint-disable-next-line react/prop-types
                    props.crtPermission?
                    <>
                        <tr>
                            <td colSpan={3} style={{...styling.td,...styling.font,...{padding: '0.5rem',borderBottom: '1px solid #eee'}}}>                                
                                <a href="#" style={{color: '#000'}} onClick={(e)=>{
                                    e.preventDefault();
                                    // eslint-disable-next-line react/prop-types
                                    props.setCrtNewAppointment(true)
                                }}><FontAwesomeIcon icon={faPlus} /> Agendar Cita</a>
                            </td>
                        </tr>
                    </>
                    :null
                }
                {
                    // eslint-disable-next-line react/prop-types
                    props.confirmPermission?
                        <tr>
                            <td colSpan={3} style={{...styling.td,...styling.font,...{padding: '0.5rem',borderBottom: '1px solid #eee'}}}>                                
                                <a href="#" style={{color: '#000'}} onClick={(e)=>{
                                    e.preventDefault();
                                    // eslint-disable-next-line react/prop-types
                                    props.setAppointmentConfirmView(true);
                                    // eslint-disable-next-line react/prop-types
                                    props.setAppointmentAssistsView(false);
                                }}><FontAwesomeIcon icon={faCheckCircle} /> Confirmar citas</a>
                            </td>
                        </tr>
                    : null
                }
                {
                    // eslint-disable-next-line react/prop-types
                    props.assistsPermission?
                        <tr>
                            <td colSpan={3} style={{...styling.td,...styling.font,...{padding: '0.5rem',borderBottom: '1px solid #eee'}}}>                                
                                <a href="#" style={{color: '#000'}} onClick={(e)=>{
                                    e.preventDefault();
                                    // eslint-disable-next-line react/prop-types
                                    props.setAppointmentAssistsView(true);
                                    // eslint-disable-next-line react/prop-types
                                    props.setAppointmentConfirmView(false);
                                }}><FontAwesomeIcon icon={faUserAlt} /> Asistencia de citas</a>
                            </td>
                        </tr>
                    :null
                }                
                {/* <tr>
                    <td colSpan={3} style={{...styling.td,...styling.font,...{padding: '0.5rem',borderBottom: '1px solid #eee'}}}>                                
                        <a href="#" style={{color: '#000'}} onClick={(e)=>{
                            e.preventDefault();
                            location.reload();
                        }}><FontAwesomeIcon icon={faHome} /> Volver al inicio</a>
                    </td>
                </tr> */}
                <tr>
                    <td colSpan={3}>
                        <label style={{fontSize: '12px',textTransform: 'capitalize',fontWeight: 'bold'}}>Progreso</label>
                        <ProgressBar now={percent} label={`%${percent}`} />
                    </td>
                </tr>
                <tr>
                    <td style={{fontSize: '12px',textTransform: 'capitalize',fontWeight: 'bold'}}>Citas</td>
                    <td style={{fontSize: '12px',textTransform: 'capitalize',fontWeight: 'bold'}}>Confirmado</td>
                    <td style={{fontSize: '12px',textTransform: 'capitalize',fontWeight: 'bold'}}>Completada</td>
                </tr>
                <tr>
                    {/* eslint-disable-next-line react/prop-types */}
                    <td style={{fontSize: '12px',textTransform: 'capitalize',fontWeight: 'bold'}}>{props.mData.myAppointments}</td>
                    {/* eslint-disable-next-line react/prop-types */}
                    <td style={{fontSize: '12px',textTransform: 'capitalize',fontWeight: 'bold'}}>{props.mData.myConfirmAppointments}</td>
                    {/* eslint-disable-next-line react/prop-types */}
                    <td style={{fontSize: '12px',textTransform: 'capitalize',fontWeight: 'bold'}}>{props.mData.myCompletedAppointments}</td>
                </tr>
                {
                    // eslint-disable-next-line react/prop-types
                    props.turn?
                        // eslint-disable-next-line react/prop-types
                        props.turn.map((turnE,idxE)=>{
                            return (
                                <tr key={idxE}>
                                    <td colSpan={2} style={{borderBottom: 'none'}}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12" style={{textAlign: 'left'}}>
                                                    <label style={{fontSize: '14px',fontWeight: 'bold',textTransform: 'capitalize'}}>Cita en progreso</label>
                                                </div>
                                            </div>                                
                                            <div className="row">
                                                <div className="col-12">                                        
                                                    <h6 style={{textAlign: 'left'}}>
                                                        {/* eslint-disable-next-line react/prop-types */}
                                                        <Badge bg="primary">{turnE.start_date}</Badge><br />
                                                        {/* eslint-disable-next-line react/prop-types */}
                                                        <span style={{fontSize: '12px'}}>Cita: #{turnE.id}</span><br />
                                                        {/* eslint-disable-next-line react/prop-types */}
                                                        <span style={{fontSize: '12px'}}>Cliente: {turnE.customer_name}</span><br />
                                                        {/* eslint-disable-next-line react/prop-types */}
                                                        <span style={{fontSize: '12px'}}>Asiste: {turnE.user_name}</span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{borderBottom: 'none'}}>
                                        {/* eslint-disable-next-line react/prop-types */}
                                        <span style={{margin: '10px'}}><FontAwesomeIcon icon={faFileInvoiceDollar} id={turnE.id} style={{cursor: 'pointer',color: '#0d72cb'}} onClick={invoiceAppointment} /></span>
                                        {/* eslint-disable-next-line react/prop-types */}
                                        <span style={{margin: '10px'}}><FontAwesomeIcon icon={faTimes} id={turnE.id} style={{cursor: 'pointer',color: '#f00'}} onClick={props.cancel} /></span>
                                    </td>
                                </tr>
                            )
                        })                    
                    :null
                }
                {
                    // eslint-disable-next-line react/prop-types
                    props.appointmentsBeforeFromDate.length?
                        <tr>
                            <td colSpan={3} style={{borderBottom: 'none'}}>
                                <label style={{fontSize: '14px',fontWeight: 'bold',textTransform: 'capitalize'}}>Citas tardías</label>
                            </td>
                        </tr>
                    :null
                }
                {
                    // eslint-disable-next-line react/prop-types
                    props.appointmentsBeforeFromDate.length?
                        // eslint-disable-next-line react/prop-types
                        props.appointmentsBeforeFromDate.map((element,idx)=>{
                            return (
                                <tr key={idx}>
                                    <td colSpan={3} style={{borderBottom: 'none'}}>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-8">
                                                    <h6 style={{textAlign: 'left'}}>
                                                        <Badge bg="primary">{element.customer_name}</Badge><br />
                                                        <span style={{fontSize: '12px'}}>Cita: #{element.id}</span><br />
                                                        <span style={{fontSize: '12px'}}>Fecha: {element.a_date}</span><br />
                                                        <span style={{fontSize: '12px'}}>Asiste: {element.user_name}</span>
                                                    </h6>
                                                    {
                                                        parseInt(updatingAppointment)===parseInt(element.id)?
                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <DateTimePicker
                                                                            onChange={setDateFilter}
                                                                            value={dateFilter}
                                                                            format="dd-MM-y"
                                                                            disableClock={true}
                                                                        />
                                                                        <FontAwesomeIcon icon={faPen} id={element.id} style={{cursor: 'pointer',color: '#0d72cb',marginLeft: '1rem'}} onClick={updateAppointmentDate} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        :null
                                                    }
                                                </div>
                                                <div className="col-4">
                                                    <FontAwesomeIcon icon={faCalendar} id={element.id} style={{cursor: 'pointer',color: '#0d72cb',margin: '1rem'}} onClick={setValueToUpdate} />
                                                    {/* eslint-disable-next-line react/prop-types */}
                                                    <FontAwesomeIcon icon={faTimes} id={element.id} style={{cursor: 'pointer',color: '#f00',margin: '1rem'}} onClick={props.cancel} />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    :null
                }
            </tbody>
        </table>
    );
}

export default UsrAppointment;