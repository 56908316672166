import React from "react";
import {Modal,Button} from 'react-bootstrap';
const PopUp = (props) => {
    return (
        // eslint-disable-next-line react/prop-types
        <Modal fullscreen={props.fullScreen!==undefined?props.fullScreen:true} show={props.show} onHide={()=>props.handle(false)}>
            <Modal.Header className="custom-modal-header" closeButton>
                <Modal.Title>
                {
                    // eslint-disable-next-line react/prop-types
                    props.tittle!==undefined?props.tittle:"Productos"
                }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* eslint-disable-next-line react/prop-types */}
                {props.content}
            </Modal.Body>
            <Modal.Footer>
                {/* eslint-disable-next-line react/prop-types*/}
                <Button variant="secondary" onClick={()=>{props.handle(false)}}>
                    Cerrar
                </Button>
                {
                    // eslint-disable-next-line react/prop-types
                    props.buttons?props.buttons:null
                }
            </Modal.Footer>
        </Modal>
    )
}
export default PopUp;