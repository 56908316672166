import React from "react";
import { nFormat } from "../../../Vendor/common";
const ProductContentView = (props) => {
    const pData = {
        // eslint-disable-next-line react/prop-types
        product_code: props.product.product_code,
        // eslint-disable-next-line react/prop-types
        description: props.product.description,
        // eslint-disable-next-line react/prop-types
        sub_category: props.product.sub_category,
        // eslint-disable-next-line react/prop-types
        file: props.product.path_file,
        // eslint-disable-next-line react/prop-types
        total_price: props.product.total_price,
        // eslint-disable-next-line react/prop-types
        unit: props.product.unit_des,
        // eslint-disable-next-line react/prop-types
        condition: props.product.condition_des,
    }
    return (
        <table className="table task-table">
            <thead>
                <tr>                    
                    {/* eslint-disable-next-line react/prop-types */}
                    <th colSpan={2} className="main-color">{pData.description}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan={2}>
                        {/* eslint-disable-next-line no-undef */}
                        <img src={(pData.file!=null&&pData.file!=undefined&&pData.file.length>0)?process.env.REACT_APP_PATH_FILE + 'files/items/' + pData.file:'/image_not_found.png'} className="img-fluid" alt="Image not found" width={500} height={500} />
                    </td>
                </tr>
                <tr>
                    <td style={{fontWeight: 'bold'}} className="main-color left-column-detail">CODIGO</td>
                    <td style={{fontWeight: 'bold'}} className="rigth-column-detail">{pData.product_code}</td>
                </tr>
                <tr>
                    <td style={{fontWeight: 'bold'}} className="main-color left-column-detail">SUB-CATEGORIA</td>
                    <td style={{fontWeight: 'bold'}} className="rigth-column-detail">{pData.sub_category}</td>
                </tr>
                <tr>
                    <td style={{fontWeight: 'bold'}} className="main-color left-column-detail">PRECIO POR UNIDAD</td>
                    <td style={{fontWeight: 'bold'}} className="rigth-column-detail">{pData.unit}</td>
                </tr>
                <tr>
                    <td style={{fontWeight: 'bold'}} className="main-color left-column-detail">PRECIO</td>
                    <td style={{fontWeight: 'bold'}} className="rigth-column-detail">${nFormat(parseFloat(pData.total_price).toFixed(2))}</td>
                </tr>
                <tr>
                    <td style={{fontWeight: 'bold'}} className="main-color left-column-detail">CONDICIÓN</td>
                    <td style={{fontWeight: 'bold'}} className="rigth-column-detail">{pData.condition}</td>
                </tr>
            </tbody>
        </table>
    );
}
export default ProductContentView;