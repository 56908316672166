import React from "react";
import clsx from "clsx";
import Proptypes from 'prop-types';

const SelectGroup = (props) => {
    const {
        index, 
        onChange, 
        value, 
        className, 
        label, 
        inputName, 
        id, 
        options, 
        inputClassName,
        defaultOptionValue,
        generalClassName,
        disabled
    } = props;
    return (
        <div className={clsx(generalClassName||'mb-3', className)}>
            {
                label?
                    <><label className='form-label'>{label}</label><br /></>
                :null
            }            
            <select 
                index={index} 
                id={id} 
                name={inputName} 
                className={clsx('form-control',inputClassName||'form-control-lg')}
                onChange={onChange}
                // defaultValue={value}
                value={value}
                keyid={inputName}
                idx={index}
                disabled={disabled}
            >
                <option key={'option-0'} value={0}>{ defaultOptionValue ? defaultOptionValue : 'Seleccionar'}</option>
                {
                    options.map((element,idx)=>{
                        return <option key={'option-'+idx} value={element.value}>{element.description.toString().toUpperCase()}</option>
                    })
                }
            </select>
        </div>
    );
}

SelectGroup.propTypes = {
    index: Proptypes.number,
    onChange: Proptypes.func.isRequired,
    value: Proptypes.any.isRequired,
    className: Proptypes.string,
    label: Proptypes.node,
    inputName: Proptypes.string.isRequired,
    id: Proptypes.string,
    options: Proptypes.array,
    inputClassName: Proptypes.string,
    defaultOptionValue: Proptypes.string,
    generalClassName: Proptypes.string,
    disabled: Proptypes.bool
}

export default SelectGroup;