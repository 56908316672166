import React,{useState,useEffect} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { nFormat } from '../Vendor/common';
import { useNavigate } from 'react-router';
import DateTimePicker from 'react-datetime-picker';
import BBox from './bBox';
import { ListGroup } from 'react-bootstrap';
const defaultMiscellaneous = {
    currencies: null,
    conditions: null,
    conditionWD: null,
    soldBySeller: null,
    terminal: null,
    date: null,
    bb: 0,
    bbDate: null
}
const defaultClosing = {
    closing: null,
    summary: [],
    denoms: []
}
const BusinessBox = () => {
    const [miscellaneous,setMiscellaneous] = useState(defaultMiscellaneous),
        [handleData,setHandleData] = useState(false),
        [isClosing,setIsClosing] = useState(true)/** Always show close */,
        [currDistribution,setCurrDistribution] = useState([]),
        [dateFilter,setDateFilter] = useState(new Date()),
        [closing,setClosing] = useState(defaultClosing),
        [currencySummary,setCurrencySummary] = useState([]),
        navigate = useNavigate();    
    useEffect(async()=>{
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API+'get/general/data',{token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
                data = resource.data;
            if(!data.status){
                Swal.fire({
                    icon:'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                setHandleData(false);
                return;
            }
            /**check amount */            
            let checkAmount = false;
            for(let i=0;i<data.conditionWD.length;i++){
                const obj = data.conditionWD[i],
                curr = obj.currency;
                for(let c=0;c<curr.length;c++){                    
                    if(parseFloat(curr[c].val)>0){
                        //There is at least one amount
                        checkAmount = true;
                        break;
                    }
                }
            }
            setMiscellaneous({
                currencies: data.currencies,
                conditions: data.conditions,
                conditionWD: data.conditionWD,
                soldBySeller: data.soldByUser,
                terminal: data.terminal,
                date: data.date
            });
            //There is at least one amount
            if(checkAmount)
                setHandleData(true);

            //set data of closing found
            setClosing(data.closing);

            /**SAVE CURRENCIES*/
            let currSumm = [];
            if(data.currencies){                
                for(let c=0;c<data.currencies.length;c++){
                    let obj = data.currencies[c];
                    obj.amount = 0;
                    currSumm.push(obj);
                }
                if(data.conditionWD){
                    data.conditionWD.map((e)=>{
                        e.currency.map((element)=>{
                            for(let counter=0;counter<currSumm.length;counter++){
                                if(currSumm[counter].currency_id==element.currency_id){
                                    currSumm[counter].amount += parseFloat(element.val);
                                }
                            }
                        })
                    })
                }                                
            }
            setCurrencySummary(currSumm);

        } catch(error){
            Swal.fire({
                icon:'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    },[]);
    const closeBusinessBox = (e) => {
        e.preventDefault();
        setIsClosing(true);
    }
    // eslint-disable-next-line no-unused-vars
    const cancelCloseBusinessBox = (e) => {
        e.preventDefault();
        setIsClosing(false);
    }
    const setDistribution = (input) => {
        const condition = parseInt( input.target.getAttribute('cond') ),
            currency = parseInt( input.target.getAttribute('curr') ),
            amount = parseFloat( input.target.value ),
            localDistribution = [...currDistribution];
        let inArr = false;
        for(let i=0;i<localDistribution.length;i++){
            const obj = localDistribution[i];
            if(obj.currency==currency&&obj.condition==condition){
                localDistribution[i].amount = amount;
                inArr = true;
                break;
            }
        }
        if(!inArr){
            localDistribution.push({condition:condition,currency:currency,amount:amount});
        }
        setCurrDistribution(localDistribution);
    }
    const processCloseBusinessBox = async (e) => {
        e.preventDefault();
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API+'process/close/business/box',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                distribution: currDistribution,
                bb: miscellaneous.bb,
                date: miscellaneous.bbDate
            }),
                data = resource.data;
            if(!data.status){
                Swal.fire({
                    icon:'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return;
            }
            Swal.fire({
                icon:'success',
                title: 'Éxito',
                text: data.msg
            }).then(()=>{
                navigate('/bb/history/'+data.bb);
            });
        } catch(error){
            Swal.fire({
                icon:'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const searchClosingByDate = async () => {
        if(dateFilter==null){
            Swal.fire({
                icon:'warning',
                title: 'Advertencia',
                text: 'Debe seleccionar al menos una fecha para realizar esta busqueda'
            });
            return;
        }
        const dF = new Date(dateFilter),
        date = dF.getFullYear() + '-' + (dF.getMonth()+1) + '-' + dF.getDate();
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API+'get/general/data',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                date: date
            }),
            data = resource.data;
            if(!data.status){
                Swal.fire({
                    icon:'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                setHandleData(false);
                return;
            }

            /**check amount */            
            let checkAmount = false;
            for(let i=0;i<data.conditionWD.length;i++){
                const obj = data.conditionWD[i],
                curr = obj.currency;
                for(let c=0;c<curr.length;c++){                    
                    if(parseFloat(curr[c].val)>0){
                        //There is at least one amount
                        checkAmount = true;
                        break;
                    }
                }
            }

            setMiscellaneous({
                currencies: data.currencies,
                conditions: data.conditions,
                conditionWD: data.conditionWD,
                terminal: data.terminal,                
                bb: data.bb,
                bbDate: data.dateBb
            });
            //There is at least one amount
            if(checkAmount)
                setHandleData(true);
            else
                setHandleData(false);

            //set data of closing found
            setClosing(data.closing);

            /**SAVE CURRENCIES*/
            let currSumm = [];
            if(data.currencies){                
                for(let c=0;c<data.currencies.length;c++){
                    let obj = data.currencies[c];
                    obj.amount = 0;
                    currSumm.push(obj);
                }
                if(data.conditionWD){
                    data.conditionWD.map((e)=>{
                        e.currency.map((element)=>{
                            for(let counter=0;counter<currSumm.length;counter++){
                                if(currSumm[counter].currency_id==element.currency_id){
                                    currSumm[counter].amount += parseFloat(element.val);
                                }
                            }
                        })
                    })
                }                                
            }
            setCurrencySummary(currSumm);

        } catch(error){
            Swal.fire({
                icon:'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12' style={{marginTop: '1rem'}}>
                    <button className='btn btn-primary' onClick={()=>navigate('/pos')}>Ir a factura</button>
                </div>
            </div>
            <div className='row'>
                <div className='d-flex justify-content-center' style={{marginTop: '3rem'}}>
                    <div className='col-6'>
                        <div className="card">
                            <div className="card-header">
                                Ventas por usuarios
                            </div>
                            <div className="card-body">
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Usuario</th>
                                                        <th scope="col">Condición</th>
                                                        <th scope="col">Monto</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        miscellaneous.soldBySeller?
                                                            miscellaneous.soldBySeller.map((e,i)=>{
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{e.seller_name}</td>
                                                                        <td>{e.condition_des}</td>
                                                                        <td>{e.currency_ref+' '+e.total_amount_format}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        :null
                                                    }
                                                </tbody>
                                                {/* <tfoot>
                                                    <tr>
                                                        <td style={{textAlign: 'center',fontWeight: 'bold',fontSize: '18px'}}>Tota:</td>
                                                        {                                                            
                                                            currencySummary.map((currSumm,summIdx)=>{
                                                                return <td style={{textAlign: 'center',fontWeight: 'bold',fontSize: '18px'}} key={summIdx}>{nFormat(parseFloat(currSumm.amount).toFixed(2))}</td>
                                                            })
                                                        }
                                                    </tr>
                                                </tfoot> */}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                closing.closing!=null?
                <>
                    <BBox 
                        businessBox={closing.closing}
                        summary={closing.summary}
                    />
                    <div className='d-flex justify-content-center' style={{marginTop: '1rem'}}>
                        <div className='col-8'>
                            <button className='btn btn-primary' onClick={()=>location.reload()}>Cuadre de hoy</button>
                        </div>
                    </div>                    
                </>
                :
                <div className='row'>
                    <div className='d-flex justify-content-center' style={{marginTop: '3rem'}}>
                        <div className='col-6'>
                            <div className="card">
                                <div className="card-header">
                                    Cuadre de caja                                                                
                                </div>
                                <div className="card-body">
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div className='col-3'>
                                            {
                                                miscellaneous.terminal!=null&&miscellaneous.terminal!=undefined?
                                                    <span>Terminal: <br />{miscellaneous.terminal.terminal_des}</span>
                                                : null
                                            }                                        
                                            </div>
                                            <div className='col-3'>
                                                <span style={{marginLeft: '1rem'}}>Fecha de apertura: <br />{miscellaneous.date}</span>
                                            </div>
                                            <div className='col-6'>
                                                <DateTimePicker
                                                    onChange={setDateFilter}
                                                    value={dateFilter}
                                                    format="dd-MM-y"
                                                    disableClock={true}
                                                />
                                                <button className='btn btn-link' onClick={searchClosingByDate}>Filtrar cierre</button>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 table-response'>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                        <th scope="col"></th>
                                                        {
                                                            miscellaneous.currencies?
                                                                miscellaneous.currencies.map((e,i)=>{
                                                                    return <th key={i} scope="col">{e.currency_des+'('+e.currency_ref+')'}</th>
                                                                })
                                                            :null
                                                        }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            miscellaneous.conditionWD?
                                                                miscellaneous.conditionWD.map((e,i)=>{
                                                                    return [
                                                                        <tr key={i}>
                                                                            <td>{e.condition_des}</td>
                                                                            {
                                                                                e.currency.map((element,idx)=>{                                                                                
                                                                                    return <td key={idx} style={{fontWeight: 'bold'}}>{nFormat(parseFloat(element.val).toFixed(2))}</td>
                                                                                })
                                                                            }
                                                                        </tr>,
                                                                        [
                                                                            e.condition_id == '5'?
                                                                                <tr key={i+'child'}>
                                                                                    <td colSpan={miscellaneous.conditionWD.length+1}>
                                                                                    <ListGroup variant="flush">    
                                                                                        {
                                                                                            closing.denoms.map((elm,idn)=>{
                                                                                                return <ListGroup.Item key={idn} style={{fontWeight: 'bold'}}>{elm.denom_amount} x {elm.denom_qty}</ListGroup.Item>
                                                                                            })
                                                                                        }
                                                                                        </ListGroup>
                                                                                    </td>
                                                                                </tr>
                                                                            :null
                                                                        ]
                                                                    ]
                                                                })
                                                            :null
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td style={{textAlign: 'center',fontWeight: 'bold',fontSize: '18px'}}>Tota:</td>
                                                            {                                                            
                                                                currencySummary.map((currSumm,summIdx)=>{
                                                                    return <td style={{textAlign: 'center',fontWeight: 'bold',fontSize: '18px'}} key={summIdx}>{nFormat(parseFloat(currSumm.amount).toFixed(2))}</td>
                                                                })
                                                            }
                                                        </tr>
                                                    </tfoot>
                                                    </table>
                                            </div>
                                        </div>
                                    </div>                                                                        
                                    {
                                        handleData&&!isClosing ? <a href="#" className="btn btn-danger" onClick={closeBusinessBox} style={{marginLeft: '10px'}}>Cierre de caja</a> : null
                                    }                                                                    
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="card" style={{border: 'none'}}>
                                <div className="card-header" style={{display: 'none'}}>
                                    Cuadre de caja                                                                
                                </div>
                                <div className="card-body">
                                {
                                        isClosing?
                                            <div className='container-fluid'>
                                                <div className='row' style={{marginTop: '10px'}}>
                                                    <div className='col-12'>
                                                        <h5>Cierre cuadre de caja</h5>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12 table-response'>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                            <th scope="col"></th>
                                                            {
                                                                miscellaneous.currencies?
                                                                    miscellaneous.currencies.map((e,i)=>{
                                                                        return <th key={i} scope="col">{e.currency_des+'('+e.currency_ref+')'}</th>
                                                                    })
                                                                :null
                                                            }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                miscellaneous.conditionWD?
                                                                    miscellaneous.conditionWD.map((e,i)=>{
                                                                        return (
                                                                            <tr key={i}>
                                                                                <td>{e.condition_des}</td>
                                                                                {
                                                                                    e.currency.map((element,idx)=>{
                                                                                        return <td key={idx}><input type="number" cond={e.condition_id} curr={element.currency_id} className="form-control" onChange={setDistribution} /></td>
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        )
                                                                    })
                                                                :null
                                                            }
                                                        </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        {/* <a href="#" className="btn btn-danger" onClick={cancelCloseBusinessBox}>Cancelar cierre</a> */}
                                                        <a href="#" className="btn btn-primary" onClick={processCloseBusinessBox} style={{marginLeft: '10px'}}>Procesar cierre</a>
                                                    </div>
                                                </div>
                                            </div>
                                        :null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }            
        </div>
    )
}
export default BusinessBox;