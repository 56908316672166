import React,{useState,useEffect, useRef} from "react";
import {Modal,Button,Spinner} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faCreditCard, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import axios from "axios";
import { nFormat } from "../Vendor/common";
// import Swal from 'sweetalert2';
const defaultCard = {
    voucher: "",
    paymentCard: 0
}
const ModalPayment = (props) => {
    const [payment,setPayment] = useState(0),
        [devolution,setDevolution] = useState(0),
        [paymentType,setPaymentType] = useState(5),
        [cashPaymentClass,setCashPaymentClass] = useState('active'),
        [creditCardPaymentClass,setcreditCardPaymentClass] = useState(''),
        [denom,setDenom] = useState([]),
        [ncfList,setNcfList] = useState([]),
        [ncfId,setNcfId] = useState(0),
        [dataCard,setDataCard] = useState(defaultCard),
        [isLoading,setIsLoading] = useState(false),
        [referenceInvoice,setReferenceInvoice] = useState(''),
        [tenPercent,setTenPercent] = useState(false),
        [tenPercentAmount,setTenPercentAmount] = useState(0),
        voucherRef = useRef(),
        paymentRef = useRef();

    let subTotalAccount = 0,
        taxAmountAccount = 0,
        totalAccount = 0,
        totalDiscount = 0;

    useEffect(()=>{
        let tt = totalAccount + tenPercentAmount;
        const dev = parseFloat( ( tt - payment) );
        setDevolution(dev);
    },[payment]);
    useEffect(()=>{
        if(parseFloat(dataCard.paymentCard)>=0){
            setPayment(parseFloat(dataCard.paymentCard));
        } else {
            if(dataCard.paymentCard.length==0)
                setPayment(0);
        }
    },[dataCard]);
    const manageDataCard = (input) => {
        const key = input.target.id,
            value = (input.target.value) ? parseFloat(input.target.value) : 0;
        setDataCard({
            ...dataCard,
            [key] : value
        });
    }
    const selectPaymentType = (btn) =>{
        const type = btn.currentTarget.getAttribute('id');
        setPaymentType(type);
        setPayment(0);
        if(type==5){
            setCashPaymentClass('active');
            setcreditCardPaymentClass('');
            setDataCard(defaultCard);
        } else {
            setcreditCardPaymentClass('active');
            setCashPaymentClass('');
            setDenom([]);
            voucherRef.current.value = "";
            paymentRef.current.value = "";
        }        
    }
    const handlePay = (btn) => {
        const amount = parseFloat( btn.currentTarget.getAttribute('id') ),
            paymentAmount = parseFloat( ( payment + amount ) );
        let localDenom = [...denom];
        localDenom.push(amount);
        setPayment(paymentAmount);
        setDenom(localDenom);
    }
    const removeDenom = (span) => {
        const amount = parseFloat( span.currentTarget.getAttribute('id') ),
            paymentAmount = parseFloat( (payment - amount) );
        let localDenom = [...denom];
        localDenom.splice(localDenom.indexOf(amount),1);
        setPayment(paymentAmount);
        setDenom(localDenom);
    }
    const chargeNcf = (select) => {
        const id = select.target.value,
            // eslint-disable-next-line react/prop-types
            ncfRes= props.handleNcfInfo;
        if(parseInt(id)===0){
            setNcfList([]);
            return;
        }
        // eslint-disable-next-line react/prop-types
        for(let i=0;i<ncfRes.length;i++){
            const obj = ncfRes[i];
            // eslint-disable-next-line react/prop-types
            if(parseInt(obj.ncf_identifier_id)===parseInt(id)){
                // eslint-disable-next-line react/prop-types
                setNcfList(obj.ncfs);
            }
        }
    }
    const billing = async (notPrint, sendMail) => {
        sendMail = ( sendMail !== undefined) ? sendMail : false;
        setIsLoading(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'billing/account',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                data:{
                    // eslint-disable-next-line react/prop-types
                    customer: props.handleCustomer,
                    // eslint-disable-next-line react/prop-types
                    shopping: props.handleShoppingCart,
                    methodId: paymentType,
                    ncfId: ncfId,
                    payment: payment,
                    denom: denom,
                    voucher: dataCard.voucher,
                    // eslint-disable-next-line react/prop-types
                    account: props.accountHeadId,
                    reference: referenceInvoice,
                    tenPercent: tenPercent,
                    sendMail: sendMail,
                    // eslint-disable-next-line react/prop-types
                    defineCurrencyId: props.defineCurrencyId,
                    // eslint-disable-next-line react/prop-types
                    billingUser: props.billingUser
                }
            }),
            data = await resource.data;
            if(data.error===false){
                if(sendMail){
                    //send email
                    Swal.fire({
                        title: '¿Esta seguro de enviar la factura por correo electrónico?',
                        // showDenyButton: true,
                        showCancelButton: true,
                        confirmButtonText: 'Enviar',
                        // denyButtonText: `Don't save`,
                    }).then((result)=>{
                        if (result.isConfirmed) {                            
                            sendMailToCustomer(data.invoice);
                        } else if (result.isDenied) {
                            if(notPrint){
                                // eslint-disable-next-line no-undef
                                window.open(process.env.REACT_APP_PATH_APP+data.path,"","width=1024,height=950");
                            }
                            location.reload();
                        }
                    })
                    return;
                }                
                if(notPrint){
                    // eslint-disable-next-line no-undef
                    window.open(process.env.REACT_APP_PATH_APP+data.path,"","width=1024,height=950");
                }
                location.reload();
            } else {
                setIsLoading(false);
                Swal.fire({
                    icon:'warning',
                    title: 'Advertencia',
                    text: data.text
                });
            }
        } catch (error){
            setIsLoading(false);
            Swal.fire({
                icon:'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const setReference = (input) => {
        const value = input.target.value;
        setReferenceInvoice(value);
    }
    const sendMailToCustomer = async(invoiceId) => {        
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'invoice/issued/mail', {invoiceId:invoiceId,token:localStorage.getItem('OSHEN-LOC-STR-TK')}),
            rs = await resource.data;        
            if(!rs.status){
                setIsLoading(false);                              
                Swal.fire({
                    icon:'warning',
                    title: 'Advertencia',
                    text: rs.text
                });
                return;
            }
            Swal.fire({
                icon:'success',
                title: 'Éxito',
                text: rs.text
            }).then(()=>{
                location.reload();    
            });
        } catch(error){
            console.log('error>>',error);
            setIsLoading(false);
            Swal.fire({
                icon:'warning',
                title: 'Advertencia',
                text: 'Algo pasó, favor tratar nuevamente ó contactar al administrador'
            });
        }
    }
    return (
        // eslint-disable-next-line react/prop-types
        <Modal fullscreen={true} show={props.show} onHide={()=>props.handle(false)}>
            <Modal.Header className="custom-modal-header" closeButton>
                <Modal.Title>Metodo de pago</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='container-fluid'>
                    <div className="row">
                        <div className="col-4 mb-2">
                            <label>Referencia</label>
                            <input 
                                type="text" 
                                className="form-control"
                                placeholder="Código vendedor/Referencia de factura"
                                onChange={setReference}
                                  />
                        </div>
                        <div className="col-4 mb-2" style={{paddingLeft: "40px",paddingTop: "25px"}}>
                            <div className="form-check form-switch">
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    role="switch" 
                                    id="ten_percent" 
                                    name="ten_percent" 
                                    onChange={
                                        (input) => {
                                            const value = input.target.checked;
                                            setTenPercent(value);
                                            let sTA = 0,
                                                sTT = 0;
                                                // eslint-disable-next-line react/prop-types
                                                props.handleShoppingCart.map((element)=>{
                                                    sTA += (parseFloat(element.amount));
                                                    sTT += parseFloat(element.total_amount);
                                                });
                                                let devCalc = parseFloat( ( sTT - payment) );
                                            if(value){                                                
                                                let percentAmount = sTA * 0.1;
                                                devCalc = parseFloat( ( ( sTT + percentAmount ) - payment) );
                                                setTenPercentAmount(percentAmount);                                                
                                            } else {
                                                setTenPercentAmount(0);
                                            }
                                            /**CAMBIO */
                                            setDevolution(devCalc);
                                        }
                                    }
                                    checked={tenPercent} />
                                <label className="form-check-label">Aplicar 10% de propina por ley</label>                                
                            </div>
                        </div>
                        <div className="col-4 mb-2" style={{paddingLeft: "40px",paddingTop: "25px",textAlign: 'right',fontWeight: 'bold'}}>
                            {
                                // eslint-disable-next-line react/prop-types
                                (props.billingUser!=null||props.billingUser!=undefined)?
                                    // eslint-disable-next-line react/prop-types
                                    <label className="form-check-label">Facturado por: {props.billingUser.personal_name}</label>
                                :null
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="row">
                                <div className="col-4">
                                    <label>Identificador</label>
                                    <select className="form-select" onChange={chargeNcf} defaultValue={0}>
                                        <option value={0}>Seleccionar</option>
                                        {
                                            // eslint-disable-next-line react/prop-types
                                            props.handleNcfInfo.map((element,idx)=>{
                                                return <option key={'ide-option'+idx} value={element.ncf_identifier_id}>{element.key_des}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-8">
                                    <label>NCF</label>
                                    <select className="form-select" defaultValue={0} onChange={(e)=>{
                                        setNcfId(e.target.value);
                                    }}>
                                        <option value={0}>Seleccionar NCF</option>
                                        {
                                            // eslint-disable-next-line react/prop-types
                                            ncfList.map((element,idx)=>{
                                                return <option key={'ide-option'+idx} value={element.ncf_id}>{element.ncf_type}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <hr style={{marginTop: '15px'}} />
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <button type="button" className={"btn btn-md btn-outline-primary "+cashPaymentClass} id={5} onClick={selectPaymentType} style={{width: '100%'}}>
                                        <FontAwesomeIcon icon={faMoneyBill} /> <br />
                                        Efectivo
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button type="button" className={"btn btn-md btn-outline-primary "+creditCardPaymentClass} id={6} onClick={selectPaymentType} style={{width: '100%'}}>
                                        <FontAwesomeIcon icon={faCreditCard} /> <br />
                                        Tarjeta
                                    </button>
                                </div>
                                <hr style={{marginTop: '15px'}} />
                            </div>
                            {
                                paymentType==5?
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12"><h5>Denominación</h5></div>
                                        </div>
                                        <div className="row" style={{marginTop: '15px'}}>
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={0.05} onClick={handlePay}>$0.05</button>
                                            </div>
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={0.10} onClick={handlePay}>$0.10</button>
                                            </div>
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={0.15} onClick={handlePay}>$0.15</button>
                                            </div>                                            
                                        </div>
                                        <div className="row" style={{marginTop: '15px'}}>
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={0.20} onClick={handlePay}>$0.20</button>
                                            </div>
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={0.25} onClick={handlePay}>$0.25</button>
                                            </div>
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={0.50} onClick={handlePay}>$0.50</button>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop: '15px'}}>
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={1} onClick={handlePay}>$1.00</button>
                                            </div>
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={5} onClick={handlePay}>$5.00</button>
                                            </div>
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={10} onClick={handlePay}>$10.00</button>
                                            </div>                                
                                        </div>
                                        <div className="row" style={{marginTop: '15px'}}>
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={25} onClick={handlePay}>$25.00</button>
                                            </div>
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={50} onClick={handlePay}>$50.00</button>
                                            </div>
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={100} onClick={handlePay}>$100.00</button>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop: '15px'}}>                                
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={200} onClick={handlePay}>$200.00</button>
                                            </div>
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={500} onClick={handlePay}>$500.00</button>
                                            </div>
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={1000} onClick={handlePay}>$1,000.00</button>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop: '15px'}}>                                
                                            <div className="col-4">
                                                <button type="button" className="btn btn-light" style={{width: '100%'}} id={2000} onClick={handlePay}>$2,000.00</button>
                                            </div>
                                        </div>
                                        {
                                            denom.length?
                                                <div className="row">
                                                    <hr style={{marginTop: '5px', marginBottom: '2px'}} />
                                                    <div className="col-12"><h5>Distribución</h5></div>
                                                    <div className="col-12">
                                                        <ol className="list-group list-group-numbered">
                                                            {
                                                                denom.map((value,idx)=>{
                                                                    return <li key={'denom-'+idx} className="list-group-item d-flex justify-content-between align-items-start">
                                                                                <div className="ms-2 me-auto">
                                                                                    {/* eslint-disable-next-line react/prop-types */}
                                                                                    {value<1?'$'+value:nFormat(parseFloat(value).toFixed(2))}
                                                                                </div>
                                                                                <span className="badge bg-danger rounded-pill cursor" id={value} onClick={removeDenom}>
                                                                                    <FontAwesomeIcon icon={faTimesCircle} />
                                                                                </span>
                                                                            </li>
                                                                })
                                                            }                                                            
                                                        </ol>
                                                    </div>
                                                </div>
                                            :null
                                        }
                                    </div>
                                :
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mb-3 row">
                                                    <label className="col-sm-2 col-form-label">Voucher</label>
                                                    <div className="col-sm-10">
                                                        <input type="text" ref={voucherRef} className="form-control form-control-lg" id="voucher" onChange={manageDataCard} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mb-3 row">
                                                    <label className="col-sm-2 col-form-label">Pago</label>
                                                    <div className="col-sm-10">
                                                        <input type="number" ref={paymentRef} className="form-control form-control-lg" id="paymentCard" onChange={manageDataCard} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }                            
                        </div>
                        <div className="col-8">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        {/* eslint-disable-next-line react/prop-types */}
                                        <p className="display-6">
                                            { /* eslint-disable-next-line react/prop-types */ }
                                            Facturar a: {props.handleCustomer!==null&&parseInt(props.handleCustomer.value)>0?props.handleCustomer.label:'Cliente del sistema'}, Moneda: {props.defineCurrencyRef}
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="th-class" scope="col">#</th>
                                                    <th className="th-class" scope="col">Código</th>
                                                    <th className="th-class" scope="col">Producto</th>
                                                    <th className="th-class" scope="col">Categoría</th>
                                                    <th className="th-class" scope="col">Cantidad</th>
                                                    <th className="th-class" scope="col">Precio</th>   
                                                    <th className="th-class" scope="col">Itbis</th>
                                                    <th className="th-class" scope="col">Descuento</th>                                                 
                                                    <th className="th-class" scope="col">Sub-Total</th>                                                    
                                                    <th className="th-class" scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    // eslint-disable-next-line react/prop-types
                                                    props.handleShoppingCart.map((element,index)=>{
                                                        return (<tr key={'row-product-'+index}>
                                                                    <th scope="row" className="td-class">{(index+1)}</th>
                                                                    <td className="td-class">{element.product_code}</td>
                                                                    <td className="td-class">{element.product_des}</td>
                                                                    <td className="td-class">{element.sub_category_des}</td>
                                                                    <td className="td-class" style={{textTransform: "uppercase"}}>{element.qty + ' ' +element.unit_des}</td>
                                                                    {/* eslint-disable-next-line react/prop-types */}
                                                                    <td className="td-class">{nFormat(parseFloat(element.total_price).toFixed(2))}</td>
                                                                    {/* eslint-disable-next-line react/prop-types */}
                                                                    <td className="td-class">{nFormat(parseFloat(element.total_amount_tax).toFixed(2))}</td>
                                                                    {/* eslint-disable-next-line react/prop-types */}
                                                                    <td className="td-class">{nFormat(parseFloat(element.total_discount).toFixed(2))}</td>
                                                                    {/* eslint-disable-next-line react/prop-types */}
                                                                    <td className="td-class">{nFormat(parseFloat(element.amount).toFixed(2))}</td>
                                                                    {/* eslint-disable-next-line react/prop-types */}
                                                                    <td className="td-class">{nFormat(parseFloat(element.total_amount).toFixed(2))}</td>
                                                                </tr>)                                                        
                                                    })
                                                }
                                                {
                                                    // eslint-disable-next-line react/prop-types
                                                    props.handleShoppingCart.map((element)=>{
                                                        totalAccount += parseFloat(element.total_amount);
                                                        // subTotalAccount += (parseFloat(element.total_price)*parseFloat(element.qty));
                                                        subTotalAccount += (parseFloat(element.amount));
                                                        // taxAmountAccount += (parseFloat(element.total_amount_tax)*parseFloat(element.qty));
                                                        taxAmountAccount += parseFloat(element.total_amount_tax);
                                                        totalDiscount += (parseFloat(element.discountByPrice)*parseFloat(element.qty));
                                                    })
                                                }
                                                {
                                                    
                                                    // eslint-disable-next-line react/prop-types
                                                    props.handleShoppingCart.length?
                                                        <>
                                                            <tr>
                                                                <td colSpan={8}></td>
                                                                <td className="td-summary-class">Sub-Total</td>
                                                                {/* eslint-disable-next-line react/prop-types */}
                                                                <td className="td-summary-class">{nFormat(parseFloat(subTotalAccount).toFixed(2))}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={8}></td>
                                                                <td className="td-summary-class">Itbis</td>
                                                                {/* eslint-disable-next-line react/prop-types */}
                                                                <td className="td-summary-class">{nFormat(parseFloat(taxAmountAccount).toFixed(2))}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={8}></td>
                                                                <td className="td-summary-class">10% Ley</td>
                                                                {/* eslint-disable-next-line react/prop-types */}
                                                                <td className="td-summary-class">{nFormat(parseFloat(tenPercentAmount).toFixed(2))}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={8}></td>
                                                                <td className="td-summary-class text-danger">Descuento</td>
                                                                {/* eslint-disable-next-line react/prop-types */}
                                                                <td className="td-summary-class text-danger">{nFormat(parseFloat(totalDiscount).toFixed(2))}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={8}></td>
                                                                <td className="td-summary-class text-primary" style={{fontSize: '24px'}}>Total</td>
                                                                {/* eslint-disable-next-line react/prop-types */}
                                                                <td className="td-summary-class text-primary" style={{fontSize: '24px'}}>{nFormat(parseFloat( (totalAccount + tenPercentAmount) ).toFixed(2))}</td>
                                                            </tr>
                                                        </>
                                                    :null
                                                }                                                
                                                    <tr>
                                                        <td colSpan={8}></td>
                                                        <td className="td-summary-class" style={{fontSize: '24px'}}>Pago</td>
                                                        {/* eslint-disable-next-line react/prop-types */}
                                                        <td className="td-summary-class" style={{fontSize: '24px'}}>{nFormat(parseFloat(payment).toFixed(2))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={8}></td>
                                                        <td className="td-summary-class" style={{fontSize: '24px'}}>Cambio</td>
                                                        {/* eslint-disable-next-line react/prop-types */}
                                                        <td className="td-summary-class" style={{fontSize: '24px'}}>{nFormat(parseFloat(Math.abs(devolution)).toFixed(2))}</td>
                                                    </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {
                                            !isLoading ?
                                                <>
                                                    <button className="btn btn-outline-primary" onClick={()=>billing(false)}>Facturar</button>
                                                    <button className="btn btn-outline-success" style={{marginLeft: '5px'}} onClick={()=>billing(true)}>Facturar e Imprimir</button>
                                                    <button className="btn btn-outline-dark" style={{marginLeft: '5px'}} onClick={()=>billing(false, true)}>Facturar y Enviar(E-mail)</button>
                                                </>                                                
                                            : <Button variant="success" disabled>
                                                    <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    />
                                                    Loading...
                                                </Button>
                                        }                                        
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* eslint-disable-next-line react/prop-types*/}
                <Button variant="secondary" onClick={()=>{props.handle(false)}}>
                    Cerrar
                </Button>
                {
                    // isLoading?
                    //     <Button variant="primary" disabled>
                    //         <Spinner
                    //         as="span"
                    //         animation="grow"
                    //         size="sm"
                    //         role="status"
                    //         aria-hidden="true"
                    //         />
                    //         Loading...
                    //     </Button>
                    // :
                    //     <Button variant="primary" onClick={save}>
                    //         Guardar cliente
                    //     </Button>
                }                
            </Modal.Footer>
        </Modal>
    );
}
export default ModalPayment;