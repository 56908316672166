import React,{useState,useEffect,useRef} from 'react';
import ModalProducts from './modal-products';
import ModalPayment from './modal-payments';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSearch, 
    faUserAlt, 
    faList, 
    faPlusCircle, 
    faMinusCircle, 
    faReceipt,
    faLocation, 
    faArrowTurnDown,
    faTerminal,
    faSignOut,
    // faRightFromBracket,
    faBarcode,
    faListUl,
    faBars,
    faMoneyBill,
    faClock,
    faRefresh,
    faPlus,
    // faLock,
    // faLockOpen,
    faInfoCircle,
    faRightFromBracket,
    faPrint,
    // faFileInvoice,
    // faArrowLeft
    // faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import ModalCustomer from './modal-customer';
import SideBar from '../Layouts/sideBar';
import Swal from 'sweetalert2';
import {Spinner,Row,Col,DropdownButton,Dropdown,Button} from 'react-bootstrap';
import { nFormat } from '../Vendor/common';
import { useNavigate } from 'react-router-dom';
import ModalBb from './modal-bb';
import ModalAccess from './modal-access';
import SelectGroup from './atoms/SelectGroup';
// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
const defaultUserInfo = {
    location: '',
    terminal: ''
}
const defaultPermissions = {
    pin: false,
    rAccount: false
}
const Pos = () => {
    const [modalProducts,setModalProducts] = useState(false),
        [modalCustomer,setModalCustomer] = useState(false),
        [modalPayments,setModelPayments] = useState(false),
        [modalProductsParam,setModalProductsParam] = useState({}),
        [modalCustomersParam,setCustomersParam] = useState({}),
        [modalBdParams,setBdParams] = useState({}),
        [modalBb,setModalBb] = useState(false),
        [criteria,setCriteria] = useState(''),
        [barcode,setBarcode] = useState(''),
        [products,setProducts] = useState([]),
        [customerOptions,setCustomerOptions] = useState([]),
        [cusOptionSelected,setCusOptionSelected] = useState({value: 0, label: 'Seleccionar un cliente'}),
        [subCategories,setSubCategories] = useState([]),
        [productList,setProductList] = useState([]),
        [statusSubCategories,setStatusSubCategories] = useState(false),
        [shoppingCartPos,setShoppingCartPos] = useState([]),
        [openSideBar,setOpenSideBar] = useState(false),
        [accountList,setAccountList] = useState([]),
        [accountHeadId,setAccountHeadId] = useState(0),
        [loadingProduct,setLoadingProduct] = useState(false),
        [disabledCustomerOption,setDisabledCustomerOption] = useState(false),
        [ncfInfo,setNcfInfo] = useState([]),
        [defaultCurrency,setDefaultCurrency] = useState(0),
        [defaultCurrencyLabel,setDefaultCurrencyLabel] = useState(''),
        [currencyConversionAmount,setCurrencyConversionAmount] = useState(0),
        [currenciesList,setCurrenciesList] = useState([]),
        [userPersonalName,setUserPersonalName] = useState(''),
        // [businessBox,setBusinessBox] = useState(null),
        [allowToCreateProduct,setAllowToCreateProduct] = useState(false),
        [userInfo,setUserInfo] = useState(defaultUserInfo),
        [checkBb,setCheckBb] = useState(false),
        [searchingBy,setSearchingBy] = useState(2),
        [limitData,setLimitData] = useState(0),
        [thereAreProducts,setThereAreProducts] = useState(false),
        [subCategorySelected,setSubCategorySelected] = useState(0),
        [instantProducts,setInstantProducts] = useState([]),
        [accountLoading,setAccountLoading] = useState(false),
        [accessShow,setAccessShow] = useState(false),
        [accessAllow,setAccessAllow] = useState(true),//se va a poner true por defecto a menos que el cliente decida que tiene que esta false
        navigate = useNavigate(),
        barcodeInputRef = useRef(),
        searchingInput = useRef(),
        pageScrolling = useRef(),
        [tableAccount,setTableAccount] = useState(0),
        [accountTables,setAccountTables] = useState([]),
        [selectCurrencyInputDisabled,setSelectCurrencyInputDisabled] = useState(false),
        [billingUser,setBillingUser] = useState(null),
        [posPermissions,setPosPermissions] = useState(defaultPermissions);
        /*,[editingProduct,setEditingProductId] = useState(null)*/
    let searchingByCriteria = searchingBy == 1 ? "active" : "",
        searchingByBarCode = searchingBy == 2 ? "active" : "",
        viewerOfCategories = statusSubCategories ? "active" : "";

    const consultGeneralInfo = async(defineCurrency)=>{
        defineCurrency = defineCurrency !== undefined ? defineCurrency : 0;
        setLoadingProduct(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/pos/info',
                {
                    token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                    defineCurrency: defineCurrency
                }),
                data = await resource.data;
                if(data.sessionOut){
                    // eslint-disable-next-line no-undef
                    location.href=process.env.REACT_APP_PATH_APP;
                    return;
                }
                setProducts(data.products);
                setSubCategories(data.subCategories);
                setCustomerOptions(data.customers);
                setAccountList(data.accounts);
                setLoadingProduct(false);
                setNcfInfo(data.ncf);
                setDefaultCurrency(data.defaultCurrency);
                setDefaultCurrencyLabel(data.defaultCurrencyLabel);
                setCurrenciesList(data.currenciesList);
                setUserPersonalName(data.user_personal_name);
                // setBusinessBox(data.businessBox);
                setAllowToCreateProduct(data.createProduct);
                setUserInfo({
                    ...userInfo,
                    location: data.userLocation,
                    terminal: data.terminalLabel
                });
                setCheckBb(data.businessBox);
                setBdParams({
                    currencies: data.currencies,
                    terminalId: data.terminalID,
                    terminalLabel: data.terminalLabel
                });
                setThereAreProducts(data.thereAreMore);
                setLimitData(data.limitProduct);

                setAccountTables(data.accountTables);

                /**barcode input focus */
                if(searchingBy==2)
                    barcodeInputRef.current.focus();

                if(defineCurrency>0){
                    setDefaultCurrency(defineCurrency);
                }
                setCurrencyConversionAmount(data.currencyConversion);
                /**SET PERMISSIONS */
                setPosPermissions({
                    pin: data.pinPermission,
                    rAccount: data.removeAccount
                })
        } catch(error){
            setLoadingProduct(false);
            Swal.fire({
                icon:'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    useEffect(consultGeneralInfo,[]);
    useEffect(()=>{
        let rows = [],
            columns = [];
        // eslint-disable-next-line react/prop-types
        products.map((element,index)=>{
            let pathFile = '/image_not_found.png';
            if(element.path_file){
                // eslint-disable-next-line no-undef
                pathFile = process.env.REACT_APP_PATH_FILE + 'files/items/' + element.path_file;
            }
            let colorInv = element.item_qty_inv > 0 ? '#198754' : '#dc3545';
            columns.push(
                <div key={'column-'+index} className='col-md-3'>
                    <div className="card cursor align-items-center" /*style={{margin: '10px'}}*/ id={element.product_id} onClick={addToShopping}>
                        <img src={pathFile} className="card-img-top" alt="IMAGE NOT FOUND" /*id={element.product_id} onClick={addToShopping}*/ style={{width: '100%',height: 'auto'}} />
                        <div className="card-body">
                            <p className="card-text" style={{fontSize: '10px',textAlign: 'center',marginBottom: '0'}}>{element.product_code} | {element.product_des}</p>
                            <p className='card-text' style={{fontSize: '11px',fontWeight: 'bold',textAlign: 'center',color: '#0d6efd',marginBottom: '0'}}>${nFormat(parseFloat(element.total_price).toFixed(2))}</p>
                            {/* <p className='card-text' style={{fontSize: '14px',fontWeight: 'bold',textAlign: 'center',color: colorInv,marginBottom: '0'}}>Maneja inventario {element.use_inventory_label}</p> */}
                            <p className='card-text' style={{fontSize: '11px',fontWeight: 'bold',textAlign: 'center',color: colorInv}}>Inventario:{element.item_qty_inv}</p>                            
                            {/* <a href='#' id={element.product_id} onClick={editProduct}>Editar producto</a>                             */}
                        </div>
                    </div>
                </div> 
            );            
            if(index>0&&((index+1)%4)===0){
                rows.push(<div key={'row-'+index} className='row' style={{marginBottom: '1rem'}}>{columns}</div>);
                columns = [];
            } else {
                if(products[(index+1)]===undefined){
                    rows.push(<div key={'row-'+index} className='row' style={{marginBottom: '1rem'}}>{columns}</div>);
                    columns = [];
                }
            }
        });
        /** Si no hay productos registrados */
        if(rows.length<1){
            rows.push(
                <div className='row' key={"product-not-found"}>
                    <div className='col-12'>
                        <div className="card">
                            <div className="card-body">
                                <h3>Informaciones</h3>
                                {/* eslint-disable-next-line react/no-unescaped-entities*/}
                                <FontAwesomeIcon icon={faInfoCircle} style={{color: 'blue'}} /> No tienes productos registrados, para poder realizar tus ventas será necesario que agregues aunque sea un producto. Encuentra el boton 'Productos' y procede con el registro de los mismos.<br /><br />
                                {/* eslint-disable-next-line react/no-unescaped-entities*/}
                                <FontAwesomeIcon icon={faInfoCircle} style={{color: 'blue'}} />Puedes recibir el código de 'Acceso' para poder realizar algunas de las acciones dentro de la aplicación, tal como poder remover artículos desde la lista temporal de compra.<br /><br />
                                <FontAwesomeIcon icon={faInfoCircle} style={{color: 'blue'}} />Si deseas facturar con valor fiscal, solo debes contactarnos por cualquiera de nuestras vías y con gusto te atenderemos.<br /><br />
                                Si tienes alguna duda ó te gustaria adquirir un plan mas avanzado, puedes contactarnos vía <a href='https://www.oshencore.com/#/contact/us' target="_blank" rel="noreferrer">www.oshencore.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        setProductList(rows);
    },[products]);
    useEffect(()=>{
        if(searchingBy==2){
            barcodeInputRef.current.focus();
        }
    },[searchingBy]);
    useEffect(()=>{
        if(instantProducts.length)
            instantAddToShopping();
    },[instantProducts]);
    useEffect(()=>{
        if(shoppingCartPos.length>0){
            setSelectCurrencyInputDisabled(true);
        } else {
            setSelectCurrencyInputDisabled(false);
        }
    },[shoppingCartPos]);
    const openArticleModel = async () => {
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/product/info',{token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
                data = await resource.data;
            setModalProductsParam(data);
            setModalProducts(true);
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const openCustomerModal = async() => {
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/customer/info'),
                data = await resource.data;
                setCustomersParam(data);
            setModalCustomer(true);
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }        
    }
    const searchProducts = async() => {
        setLoadingProduct(true);
        setSubCategorySelected(0);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/products',{
                criteria: criteria,
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                defineCurrencyId: defaultCurrency
            }),
                data = await resource.data;
                if(data.sessionOut){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: 'La sesión ha expirado, será redireccionado'
                    }).then(()=>{
                        // eslint-disable-next-line no-undef
                        location.href = process.env.REACT_APP_PATH_APP;
                    });
                    return false;
                }
                setProducts(data.products);
                setThereAreProducts(data.thereAreMore);
                setLimitData(data.limitProduct);
                setLoadingProduct(false);
                /**clear input*/
                searchingInput.current.value = '';
                setCriteria('');
        } catch(error){
            setLoadingProduct(false);
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const searchProductsByBarcode = async() => {
        // setLoadingProduct(true);
        setSubCategorySelected(0);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/products',{
                criteria: barcode,
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                barcoding: true,
                defineCurrencyId: defaultCurrency
            }),
                data = await resource.data;
                if(data.sessionOut){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: 'La sesión ha expirado, será redireccionado'
                    }).then(()=>{
                        // eslint-disable-next-line no-undef
                        location.href = process.env.REACT_APP_PATH_APP;
                    });
                    return false;
                }
                if(data.products.length){
                    setInstantProducts(data.products);
                    // instantAddToShopping(data.products[0].product_id);
                    barcodeInputRef.current.value = '';
                    barcodeInputRef.current.focus();
                }
                // setLoadingProduct(false);
        } catch(error){
            // setLoadingProduct(false);
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const searchBySubCategory = async(e) => {
        setLoadingProduct(true);
        setSubCategorySelected(e);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/products',{
                criteria: criteria,
                sc: e,
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                defineCurrencyId: defaultCurrency
            }),
                data = await resource.data;
                if(data.sessionOut){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: 'La sesión ha expirado, será redireccionado'
                    }).then(()=>{
                        // eslint-disable-next-line no-undef
                        location.href = process.env.REACT_APP_PATH_APP;
                    });
                    return false;
                }
                setProducts(data.products);
                setThereAreProducts(data.thereAreMore);
                setLimitData(data.limitProduct);
                setLoadingProduct(false);
        } catch(error){
            setLoadingProduct(false);
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const getProductInfo = (productId) => {
        for(let i=0;i<products.length;i++){
            if(parseInt(products[i].product_id) === parseInt(productId)){
                return products[i];
            }
        }
        return null;
    }
    const getProductInfoByInstant = () => {
        return instantProducts[0];
    }
    /**Shop methods */
    const addToShopping = (e) => {
        const pId = e.currentTarget.getAttribute('id');
        let arr = [];
        const pObj = getProductInfo(pId);
        pObj.qty = 1;
        pObj.amount = pObj.qty * pObj.total_price;
        pObj.total_amount_tax = pObj.qty * pObj.taxes_amount;
        pObj.total_discount = parseFloat(pObj.qty) * parseFloat(pObj.discountByPrice);
        pObj.total_amount = ( parseFloat( pObj.amount ) + parseFloat( pObj.total_amount_tax ) ) - parseFloat( pObj.total_discount );
        arr.push(pObj);
        setShoppingCartPos(prevSCP => ([...prevSCP, ...arr]));
    }
    const instantAddToShopping = () => {
        let arr = [];
        const pObj = getProductInfoByInstant();
        if(pObj!=null&&pObj!=undefined)
        {
            pObj.qty = 1;
            pObj.amount = pObj.qty * pObj.total_price;
            pObj.total_amount_tax = pObj.qty * pObj.taxes_amount;
            pObj.total_discount = parseFloat(pObj.qty) * parseFloat(pObj.discountByPrice);
            pObj.total_amount = ( parseFloat( pObj.amount ) + parseFloat( pObj.total_amount_tax ) ) - parseFloat( pObj.total_discount );
            arr.push(pObj);
            setShoppingCartPos(prevSCP => ([...prevSCP, ...arr]));
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'El producto no pudo ser encontrado, favor intentar luego ó contacte al administrador'
            });
        }
    }
    const removeQty = (element) => {
        let arr = [...shoppingCartPos],
            idx = element.currentTarget.getAttribute('idx');
        if(parseInt(arr[idx].qty)==1){
            arr.splice(idx,1);
        } else {
            arr[idx].qty = parseFloat(arr[idx].qty) - 1;
            arr[idx].amount = parseFloat(arr[idx].qty) * parseFloat(arr[idx].total_price);
            arr[idx].total_amount_tax = parseFloat(arr[idx].qty) * parseFloat(arr[idx].taxes_amount);
            arr[idx].total_discount = parseFloat(arr[idx].qty) * parseFloat(arr[idx].discountByPrice);
            arr[idx].total_amount = ( parseFloat(arr[idx].amount) + parseFloat(arr[idx].total_amount_tax) ) - parseFloat(arr[idx].total_discount);
        }
        setShoppingCartPos(arr);
    }
    // eslint-disable-next-line no-unused-vars
    const addQty = (element) => {
        let arr = [...shoppingCartPos],
            idx = element.currentTarget.getAttribute('idx');
        arr[idx].qty = parseFloat(arr[idx].qty) + 1;
        arr[idx].amount = parseFloat(arr[idx].qty) * parseFloat(arr[idx].total_price);
        arr[idx].total_amount_tax = parseFloat(arr[idx].qty) * parseFloat(arr[idx].taxes_amount);
        arr[idx].total_discount = parseFloat(arr[idx].qty) * parseFloat(arr[idx].discountByPrice);
        arr[idx].total_amount = ( parseFloat(arr[idx].amount) + parseFloat(arr[idx].total_amount_tax) ) - parseFloat(arr[idx].total_discount);
        setShoppingCartPos(arr);        
    }
    const handleSideBarShow = () => {
        if(openSideBar)
            setOpenSideBar(false)
        else
            setOpenSideBar(true)
    }
    const createAccount = async () => {
        if(shoppingCartPos.length<1){
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: "Debes seleccionar al menos un producto"
            });
            return;
        }
        if(cusOptionSelected===null){
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: "Debes seleccionar al menos un cliente"
            });
            return;
        }
        if(parseInt(cusOptionSelected.value)<1){
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: "Debes seleccionar al menos un cliente"
            });
            return;
        }
        setAccountLoading(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = axios.post(process.env.REACT_APP_PATH_API + 'save/account',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                data: shoppingCartPos,
                customer: cusOptionSelected.value,
                accountHeadId: accountHeadId,
                tableAccount: tableAccount,
                defineCurrencyId: defaultCurrency
            }),
                resourceData = await resource,
                data = resourceData.data;
            if(data.status){
                setAccountList(data.accounts);
                setShoppingCartPos([]);
                setCusOptionSelected(null);
                handleSideBarShow(true);
                setAccountHeadId(0);
                setTableAccount(0);
                setDisabledCustomerOption(false);
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
            }
            setAccountLoading(false);
        } catch(e){
            setAccountLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const openNewAccount = () => {
        setShoppingCartPos([]);
        const inputCustomerOption = {value: 0, label: 'Seleccionar un cliente'};
        setCusOptionSelected(inputCustomerOption);
        setAccountHeadId(0);
        setDisabledCustomerOption(false);
        setTableAccount(0);
    }
    const openBusinessBox = () => {
        // eslint-disable-next-line no-undef
        // window.open(process.env.REACT_APP_PATH_APP + 'business/box?app=83',"","width=1024,height=758");
        navigate('/businessbox');
    }
    const openTurn = () => {        
        setModalBb(true)
    }    
    const giveMeMoreProducts = async() => {
        let giveMeMore = limitData + 10;
        setLoadingProduct(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API+'get/products',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                limitData: giveMeMore,
                sc: subCategorySelected,
                defineCurrencyId: defaultCurrency
            }),
                data = resource.data;
                if(data.sessionOut){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: 'La sesión ha expirado, será redireccionado'
                    }).then(()=>{
                        // eslint-disable-next-line no-undef
                        location.href = process.env.REACT_APP_PATH_APP;
                    });
                    return false;
                }
                setProducts(data.products);
                setThereAreProducts(data.thereAreMore);
                setLimitData(data.limitProduct);
                setLoadingProduct(false);
                /*---Scrolling---*/
                pageScrolling.current?.scrollIntoView({behavior: 'smooth'});
        } catch (error) {
            console.log('error>>',error);
            setLoadingProduct(false);
            Swal.fire({
                icon:'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }        
    }
    const refreshProducts = async() => {
        setLoadingProduct(true);
        setSubCategorySelected(0);
        setCriteria('');
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/products',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                defineCurrencyId: defaultCurrency
            }),
                data = await resource.data;
                if(data.sessionOut){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: 'La sesión ha expirado, será redireccionado'
                    }).then(()=>{
                        // eslint-disable-next-line no-undef
                        location.href = process.env.REACT_APP_PATH_APP;
                    });
                    return false;
                }
                setProducts(data.products);
                setThereAreProducts(data.thereAreMore);
                setLimitData(data.limitProduct);
                setLoadingProduct(false);
        } catch(error){
            setLoadingProduct(false);
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const changeDefaultCurrency = async(input) => {
        const /*key = input.target.id,*/
            value = input.target.value;
        try{
            consultGeneralInfo( value );
            //setDefaultCurrency(value);
        } catch(error){
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }        
    }
    const addItemByNumber = (input) => {
        const idx = input.target.getAttribute('id');
        let sp = shoppingCartPos[idx],
            arr = [...shoppingCartPos];
        Swal.fire({
            title: `¿Cuántos ${sp.product_des} te gustaría agregar?`,
            input: 'number',
            showCancelButton: true,
            confirmButtonText: 'Agregar Productos',
        }).then((result)=>{
            if(result.isConfirmed){
                const value = parseInt(result.value);
                for(let counter=1;counter<=value;counter++){
                    arr.push(sp);
                }                
                setShoppingCartPos(arr);
            }
        });
    }
    const searchBillingUser = () => {
        if(!posPermissions.pin){
            setModelPayments(true);
            return;
        }
        Swal.fire({
            title: 'Ingresa tu PIN para facturar',
            input: 'password',
            inputAttributes: {
                maxlength: 4
            },
            showCancelButton: true,
            confirmButtonText: 'Validar'
        }).then(async(result)=>{
            if(result.isConfirmed){
                // eslint-disable-next-line no-undef
                const resource = await axios.post(process.env.REACT_APP_PATH_API + 'check/access/pin',{
                    token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                    pin: result.value
                }),
                data = await resource.data;
                if(!data.status){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: data.message
                    });
                    return;
                }
                setBillingUser(data.usr);
                setModelPayments(true);
            }
        });
    }
    const printTurnTicket = async() => {
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'turn/info',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK')
            }),
            data = await resource.data;
            // eslint-disable-next-line no-undef
            window.open(process.env.REACT_APP_PATH_APP+data.path,"","width=1024,height=950");
        } catch(error) {
            console.log('Error', error);
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    // const editProduct = async (e) => {
    //     e.preventDefault();
    //     const id = e.currentTarget.getAttribute('id');
    //     console.log('Producto>>',id);
    //     try{
    //         // eslint-disable-next-line no-undef
    //         const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/products',{
    //             token: localStorage.getItem('OSHEN-LOC-STR-TK'),
    //             pId: id
    //         }),
    //         data = await resource.data;
    //         setEditingProductId(data.products);
    //     } catch (error){
    //         Swal.fire({
    //             icon:'error',
    //             title: 'Error interno',
    //             text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
    //         });
    //     }
    // }
    let scAmount = 0,
        scSubTotal = 0,
        scTaxes = 0,
        scDiscount = 0;
    return (
        <>
        <SideBar 
            show={openSideBar} 
            handleShow={handleSideBarShow} 
            accounts={accountList}
            accountSetter={setAccountList} 
            formatter={formatter} 
            handleShoppingCart={setShoppingCartPos} 
            handleCusRef={setCusOptionSelected}
            handleAccountHeadId={setAccountHeadId}
            setDisabledCustomerOption={setDisabledCustomerOption}
            setTableAccount={setTableAccount}
            setDefaultCurrency={setDefaultCurrency}
            setSelectCurrencyInputDisabled={setSelectCurrencyInputDisabled}
            removePermission={posPermissions.rAccount}
        />
        <div className='container-fluid'>
            {/* <div className="row">
                <div className="col-12" style={{padding:'0.5rem',paddingLeft:'2rem'}}>
                    <a href={process.env.REACT_APP_PATH_APP} className="navbar-brand" style={{fontSize: '14px'}}><FontAwesomeIcon icon={faArrowLeft} />Salir de aplicación</a>
                </div>
            </div> */}
            <div className='row'>
                <div className='col-8' style={{paddingRight: '0'}}>
                    <nav className="navbar navbar-light bg-color headers-styling nav-bar">
                        <div className="container-fluid">
                            {/* <a href='#' className="navbar-brand"><FontAwesomeIcon icon={faArrowLeft} /></a> */}
                            {/* <FontAwesomeIcon
                                icon={faArrowLeft} 
                                style={{cursor:'pointer'}}
                                // eslint-disable-next-line no-undef
                                onClick={()=>location.href=process.env.REACT_APP_PATH_APP}
                            /> */}
                            <button 
                                type="button" 
                                className="btn btn-primary position-relative btn-md" 
                                style={{color: "#fff"}}
                                onClick={handleSideBarShow}
                            >
                                <FontAwesomeIcon icon={faReceipt} />
                                {
                                    accountList.length?
                                        <span 
                                            className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle badge-accounts"
                                        >
                                            {accountList.length}<span className="visually-hidden">-</span>
                                        </span>
                                    : null
                                }                                
                            </button>
                            {/* </a> */}
                            <form className="d-flex">
                                {/* {
                                    accessAllow?
                                    <button className='btn btn-success btn-sm' type='button' style={{marginLeft: 10}} onClick={()=>setAccessAllow(false)}>
                                        <FontAwesomeIcon icon={faLockOpen} /> Liberado
                                    </button>
                                    :<button className='btn btn-danger btn-sm' type='button' style={{marginLeft: 10}} onClick={()=>{setAccessShow(true)}}>
                                        <FontAwesomeIcon icon={faLock} /> Acceso
                                    </button>
                                }                                 */}
                                {/* <button className='btn btn-secondary btn-sm' type='button' style={{marginLeft: 10}} onClick={refreshProducts}>
                                    <FontAwesomeIcon icon={faFileInvoice} /> Facturado hoy
                                </button> */}
                                <button className='btn btn-secondary btn-sm' type='button' style={{marginLeft: 10}} onClick={refreshProducts}>
                                    <FontAwesomeIcon icon={faRefresh} /> Refrescar
                                </button>
                                <button className='btn btn-secondary btn-sm' type='button' style={{marginLeft: 10}} onClick={()=>navigate('/bb/history')}>
                                    <FontAwesomeIcon icon={faClock} /> Turnos
                                </button>
                                {
                                    checkBb ?                                         
                                        <button className="btn btn-secondary btn-sm" type="button" style={{marginLeft: 10}} onClick={openBusinessBox}>
                                            <FontAwesomeIcon icon={faMoneyBill} /> Cuadre
                                        </button>
                                    : null
                                }                                
                                {
                                    allowToCreateProduct ? 
                                        <button className="btn btn-secondary btn-sm" type="button" style={{marginLeft: 10}} onClick={openArticleModel}>
                                            <FontAwesomeIcon icon={faBars} /> Productos
                                        </button>
                                    : null
                                }                                
                            </form>
                        </div>
                    </nav>
                </div>
                <div className='col-4' style={{paddingLeft: '0'}}>
                    <nav className="navbar navbar-light bg-light headers-styling nav-side nav-bar" style={{height: '100%'}}>
                        <div className="container-fluid" style={{paddingLeft: '0px'}}>
                            <DropdownButton type="button" variant='secondary' size="sm" title={[<FontAwesomeIcon icon={faUserAlt} key='icon-name' />,' ',userPersonalName]}>
                                <Dropdown.ItemText>Informaciones de turno</Dropdown.ItemText>
                                <Dropdown.ItemText><FontAwesomeIcon icon={faLocation} /> {userInfo.location}</Dropdown.ItemText>
                                <Dropdown.ItemText><FontAwesomeIcon icon={faTerminal} /> {userInfo.terminal}</Dropdown.ItemText>
                                <hr />
                                {
                                    !checkBb?
                                        <Dropdown.Item as="button" type='button' onClick={openTurn}><FontAwesomeIcon icon={faArrowTurnDown} key='icon-name' /> Abrir Turno</Dropdown.Item>
                                    :   <Dropdown.Item as="button" type='button' onClick={printTurnTicket}><FontAwesomeIcon icon={faPrint} key='print-turn-ticket' /> Ver Ticket de Turno</Dropdown.Item>
                                }                                
                                {/* eslint-disable-next-line no-undef */}
                                <Dropdown.Item as="button" type='button' onClick={()=>location.href=process.env.REACT_APP_PATH_APP}><FontAwesomeIcon icon={faRightFromBracket} /> Salir de la aplicación</Dropdown.Item>
                                { /* eslint-disable-next-line no-undef */ }
                                <Dropdown.Item as="button" type='button' onClick={()=>location.href=process.env.REACT_APP_PATH_APP+"log-out"}><FontAwesomeIcon icon={faSignOut} /> Cerrar Sesión</Dropdown.Item>
                            </DropdownButton>
                            <span style={{fontSize: '14px',fontWeight: 'bold', marginLeft: '5%'}}>
                                Factura en:
                            </span>
                            <SelectGroup 
                                onChange={changeDefaultCurrency} 
                                value={defaultCurrency} 
                                className="col-2" 
                                inputName="posInvoiceCurrency" 
                                id="posInvoiceCurrency"
                                options={(Object.keys(currenciesList).length>0)?currenciesList:[]}
                                inputClassName='form-control-sm'
                                generalClassName='mb-0'
                                defaultOptionValue="==="
                                disabled={selectCurrencyInputDisabled}
                            />
                            <span style={{fontSize: '14px',fontWeight: 'bold'}}>
                                Tasa: {currencyConversionAmount!=1?currencyConversionAmount:'N/A'}
                            </span>
                            {/* <a className="navbar-brand" style={{fontSize: '14px',fontWeight: 'bold'}}>Factura en {defaultCurrencyLabel}</a> */}
                        </div>
                    </nav>
                </div>
            </div>
            <div className='row'>
                <div className='col-3'></div>
                <div className='col-6'>
                    <div className="input-group mb-1" style={{marginTop: '1rem'}}>                        
                        {/* <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)" /> */}
                        <button className="btn btn-outline-success" type="button" onClick={openCustomerModal}><FontAwesomeIcon icon={faPlusCircle} /> Nuevo Cliente</button>
                        <Select 
                            options={customerOptions} 
                            className="form-control" 
                            value={cusOptionSelected}
                            defaultValue={cusOptionSelected} 
                            onChange={setCusOptionSelected}
                            isDisabled={disabledCustomerOption}
                                />                        
                        {/* <span className="input-group-text">Nuevo cliente</span> */}
                    </div>
                </div>
                <div className='col-3'>
                    <div className="input-group mb-1" style={{marginTop: '1rem'}}>
                        <SelectGroup 
                            onChange={(input)=>{
                                const /*key = input.target.id,*/
                                    value = input.target.value;
                                setTableAccount(value);
                            }} 
                            value={tableAccount} 
                            className="col-12" 
                            // label={
                            //     <>
                            //         ¿El cliente utiliza una mesa?
                            //     </>
                            // }
                            inputName="tableAccount" 
                            id="tableAccount"
                            options={(Object.keys(accountTables).length>0)?accountTables:[]}
                            // inputClassName='form-control-md'
                            defaultOptionValue="¿El cliente utilizará una mesa?"
                        />
                    </div>
                </div>                
            </div>
            <div className='row'>
                <div className='col-8 ei-height'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12' style={{padding: '15px'}}>
                                <div className="input-group">
                                    <span onClick={()=>setSearchingBy(1)} className={`input-group-text btn btn-outline-secondary ${searchingByCriteria}`}><FontAwesomeIcon icon={faListUl} /></span>
                                    <span onClick={()=>setSearchingBy(2)} className={`input-group-text btn btn-outline-secondary ${searchingByBarCode}`}><FontAwesomeIcon icon={faBarcode} /></span>
                                    {
                                        searchingBy==1?
                                            <>
                                                <input 
                                                    type="text"
                                                    ref={searchingInput}
                                                    className="form-control" 
                                                    placeholder="Buscar producto por criterio" 
                                                    onKeyDown={(e)=>{
                                                        if(e.key==='Enter'){
                                                            searchProducts();
                                                        }
                                                    }}
                                                    onChange={(input)=>{                                        
                                                        setCriteria(input.target.value)
                                                    }} />
                                                <button className="btn btn-outline-primary" type="button" onClick={searchProducts}><FontAwesomeIcon icon={faSearch} /></button>
                                                <button className={`btn btn-outline-secondary ${viewerOfCategories}`} type="button" onClick={()=>{setStatusSubCategories(statusSubCategories ? false : true);}}><FontAwesomeIcon icon={faList} /></button>
                                            </>
                                        :
                                            <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Escanear código de barra"
                                            ref={barcodeInputRef}
                                            onKeyDown={(e)=>{
                                                if(e.key==='Enter'){
                                                    searchProductsByBarcode();
                                                }
                                            }}
                                            onChange={(input)=>{                                        
                                                setBarcode(input.target.value)
                                            }} />
                                    }                                
                                </div>
                            </div>
                        </div>
                        {
                            statusSubCategories ?
                                <div className='row'>
                                    <div className='col-12 container-carusel-custom mb-3'>
                                        <div className="carousel-custom">
                                            <div className="item-custom" onClick={()=>searchBySubCategory(0)}>
                                                <div className="card">
                                                    <img src="/img_not_found.png" className="card-img-top" alt="SUB-CATEGORY-IMG" />
                                                </div>
                                                <h1>Productos</h1>
                                            </div>
                                            {
                                                subCategories.map((element,index)=>{
                                                    // eslint-disable-next-line no-undef
                                                    const photo = element.path_file ? process.env.REACT_APP_PATH_APP + element.path_file : '/img_not_found.png';
                                                    return (
                                                        <div key={index} className="item-custom" onClick={()=>searchBySubCategory(element.sub_category_id)}>
                                                            <div className="card">
                                                                <img src={photo} className="card-img-top" alt="SUB-CATEGORY-IMG" />
                                                            </div>
                                                            <h1>{element.sub_category_des}</h1>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            : null
                        }                    
                        {
                            !loadingProduct ?
                                productList
                            :<Row>
                                <Col md={{ span: 3, offset: 6 }} style={{marginTop: '25%'}}>
                                    <Spinner animation="border" variant="primary" />
                                </Col>
                            </Row>
                        }
                        {
                            thereAreProducts?
                                <div className='d-flex justify-content-center' style={{marginTop: '3rem'}}>
                                    <Button variant="outline-primary" onClick={giveMeMoreProducts}>Cargar mas productos...</Button>
                                </div>
                            :null
                        }
                    </div>
                    <div ref={pageScrolling} />
                </div>

                <div className='col-4 ei-height border-dotted' style={{paddingTop: '5px'}}>                    
                    <div className='row mb-3'>
                        <div className='col-12 terf-height' style={{paddingRight: 0}}>
                            <table className="table">
                                <tbody>
                                {
                                    shoppingCartPos.map((element,index)=>{
                                        const prdQty = element.qty ? element.qty : 0;
                                        return (
                                            <tr key={index}>
                                                <th scope="row" style={{width: '5%'}} className='top-padding-5p'>{(index+1)}</th>
                                                <td style={{width: '55%'}} className='top-padding-5p'>
                                                    { element.product_code + '/' + element.product_des}<br />
                                                    <span style={{fontSize: '11px',marginRight: '5px',color: 'rgb(25 135 84)',fontWeight: 'bold'}}>Prec.${nFormat(parseFloat(element.total_price).toFixed(2))}</span>
                                                    <span style={{fontSize: '11px',marginRight: '5px',color: '#000000',fontWeight: 'bold'}}>Imp.${nFormat(parseFloat(element.taxes_amount).toFixed(2))}</span>
                                                    <span style={{fontSize: '11px',color: '#f00',fontWeight: 'bold'}}>Des.${nFormat(parseFloat(element.discountByPrice).toFixed(2))}</span>
                                                    <span style={{fontSize: '11px',color: '#000000',fontWeight: 'bold',marginLeft: '5px',textTransform: 'uppercase'}}>{element.unit_des}</span>
                                                </td>
                                                <td style={{width: '20%'}} className='top-padding-5p'>
                                                    <div className="input-group mb-3">
                                                        {
                                                            accessAllow?
                                                                <span idx={index} className="input-group-text btn btn-danger zIndex" onClick={removeQty} style={{paddingTop: 0,paddingBottom: 0,paddingRight: 5,paddingLeft: 5}}><FontAwesomeIcon icon={faMinusCircle} /></span>
                                                            :null
                                                        }                                                        
                                                        <input 
                                                            id={index}
                                                            type="number" 
                                                            className="form-control" 
                                                            value={prdQty} 
                                                            readOnly={true} 
                                                            style={{
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                paddingRight: 5,
                                                                paddingLeft: 5,
                                                                textAlign: 'center',
                                                                cursor: 'pointer'
                                                            }}
                                                            title="Selecciona para agregar mas productos"
                                                            onClick={addItemByNumber} />
                                                        {/* <span idx={index} className="input-group-text btn btn-success zIndex" onClick={addQty} style={{paddingTop: 0,paddingBottom: 0,paddingRight: 5,paddingLeft: 5}}><FontAwesomeIcon icon={faPlusCircle} /></span> */}
                                                    </div>
                                                </td>
                                                <td style={{width: '20%',textAlign: 'right',fontWeight: 'bold',color: '#000000'}} className='top-padding-5p'>${nFormat(parseFloat(element.total_amount).toFixed(2))}</td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        //shoppingCartPos.length ?
                        // eslint-disable-next-line no-constant-condition
                        true ?
                        <>
                            <div className="card text-bg-light mb-1" style={{maxWidth: '100%'}}>
                                {/* <div className="card-header">Header</div> */}
                                <div className="card-body" style={{backgroundColor: '#283d62'}}>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <table className="table table-bordered">
                                                <tbody>
                                                    {
                                                        shoppingCartPos.map((element)=>{
                                                            scAmount += parseFloat(element.total_amount);
                                                            scSubTotal += (parseFloat(element.total_price)*parseFloat(element.qty));
                                                            scTaxes += (parseFloat(element.taxes_amount)*parseFloat(element.qty));
                                                            scDiscount += (parseFloat(element.discountByPrice)*parseFloat(element.qty));
                                                        })
                                                    }
                                                    <tr>
                                                        <td className='text-info bg-color-td' style={{textAlign: 'right',width: '80%',fontWeight: 'bold'}}>Sub-Total</td>
                                                        <td className='text-info bg-color-td' style={{textAlign: 'right',width: '20%',fontWeight: 'bold'}}>${nFormat(parseFloat(scSubTotal).toFixed(2))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-success bg-color-td' style={{textAlign: 'right',width: '80%',fontWeight: 'bold'}}>Impuesto</td>
                                                        <td className='text-success bg-color-td' style={{textAlign: 'right',width: '20%',fontWeight: 'bold'}}>${nFormat(parseFloat(scTaxes).toFixed(2))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-danger bg-color-td' style={{textAlign: 'right',width: '80%',fontWeight: 'bold'}}>Descuento</td>
                                                        <td className='text-danger bg-color-td' style={{textAlign: 'right',width: '20%',fontWeight: 'bold'}}>${nFormat(parseFloat(scDiscount).toFixed(2))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-primary bg-color-td' style={{textAlign: 'right',width: '80%',fontWeight: 'bold'}}>Monto</td>
                                                        <td className='text-primary bg-color-td' style={{textAlign: 'right',width: '20%',fontWeight: 'bold'}}>${nFormat(parseFloat(scAmount).toFixed(2))}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                            <div className='row mb-1'>
                                <div className='col-6'>
                                    {
                                        !accountLoading?
                                            <button className='btn btn-outline-primary' style={{width: '100%',fontSize: '14px'}} onClick={createAccount}><FontAwesomeIcon icon={faReceipt} />{accountHeadId>0?" ACTUALIZAR CUENTA ":" ABRIR CUENTA "}</button>
                                        :<Button variant="outline-primary" disabled>
                                            <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            />
                                            Loading...
                                        </Button>                                        
                                    }                                    
                                </div>
                                <div className='col-6'>
                                    {/*setModelPayments(true)*/}
                                    <button className='btn btn-outline-success' style={{width: '100%',fontSize: '14px'}} onClick={searchBillingUser}><FontAwesomeIcon icon={faMoneyBill} /> FACTURAR CUENTA</button>
                                </div>                                
                            </div>
                            <div className='row'>
                                {
                                    accountHeadId>0?
                                        <div className='col-6'>
                                            <button className='btn btn-outline-secondary' style={{width: '100%',fontSize: '12px'}} onClick={openNewAccount}><FontAwesomeIcon icon={faPlus} /> ABRIR NUEVA CUENTA</button>
                                        </div>
                                    :null
                                }
                            </div>
                        </>
                        : null
                    }
                </div>
            </div>
        </div>
        <ModalProducts 
            show={modalProducts} 
            params={modalProductsParam} 
            handle={setModalProducts} 
            handleProducts={setProducts}
            defaultCurrency={defaultCurrency}
            defaultCurrencyLabel={defaultCurrencyLabel}
            /*editingProduct={editingProduct}*/ />
        <ModalCustomer 
            show={modalCustomer} 
            params={modalCustomersParam} 
            handle={setModalCustomer} 
            handleCustomers={setCustomerOptions} />
        <ModalPayment 
            show={modalPayments} 
            handle={setModelPayments} 
            handleShoppingCart={shoppingCartPos} 
            handleCustomer={cusOptionSelected} 
            handleNcfInfo={ncfInfo}
            accountHeadId={accountHeadId}
            defineCurrencyId={defaultCurrency}
            defineCurrencyRef={defaultCurrencyLabel}
            billingUser={billingUser}
            />
        <ModalBb 
            show={modalBb}
            handle={setModalBb}
            datax={modalBdParams}
            bbOpen={setCheckBb}
            />
        <ModalAccess 
            show={accessShow}
            handle={setAccessShow}
            setAccessAllow={setAccessAllow}
        />
        </>
    )
}
export default Pos;