import { createSlice } from '@reduxjs/toolkit'

export const quoteItemsSlice = createSlice({
  name: 'quoteItems',
  initialState: {
    value: [],
  },
  reducers: {
    add: (state, action) => {
      console.log('action,',action);
      if(state.value.indexOf(action.payload.productId)<0){
        state.value.push(action.payload.productId);
      }      
    },
    remove: (state, action) => {
      const index = state.value.indexOf(parseInt(action.payload.productId));
      if(index>=0){
        state.value.splice(index,1);
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { add, remove } = quoteItemsSlice.actions

export default quoteItemsSlice.reducer