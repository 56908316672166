import React, {useState, useEffect} from 'react';
import {Modal,Button,Spinner} from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import DateTimePicker from 'react-datetime-picker';
const defaultCustomer = {
    cus_name: '',
    cus_phone: '',
    cus_email: '',
    cus_document: '',
    cus_document_type: 0,
    cus_bd_date: ''
}
const ModalCustomer = (props) => {
    const [customer,setCustomer] = useState(defaultCustomer),
        [isLoading,setIsLoading] = useState(false),
        [manageDate,setManageDate] = useState(null);
    useEffect(()=>{
        let mDate = '';
        if(manageDate!==null){
            const date = new Date(manageDate);
            mDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }
        setCustomer({
            ...customer,
            cus_bd_date : mDate
        });
    },[manageDate]);
    const handleData = (input) => {
        const key = input.target.id,
            value = input.target.value;
        setCustomer({
            ...customer,
            [key] : value
        });
    }
    const save = async() => {
        setIsLoading(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'save/customer',{token: localStorage.getItem('OSHEN-LOC-STR-TK'),data: customer}),
                data = await resource.data;
            if(data.status){
                Swal.fire({
                    icon: 'success',
                    title: 'Éxito',
                    text: data.msg
                }).then(()=>{
                    setIsLoading(false);
                    /* eslint-disable-next-line react/prop-types */
                    props.handleCustomers(data.customers);
                    /* eslint-disable-next-line react/prop-types */
                    props.handle(false);
                    setCustomer(defaultCustomer);
                    setManageDate(null);
                });
            } else {
                setIsLoading(false);
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });                
            }
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Advertencia',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    return (
        // eslint-disable-next-line react/prop-types
        <Modal size='md' show={props.show} onHide={()=>props.handle(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Creación de producto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 mb-3'>
                            <label className='form-label'>Nombre de cliente <span style={{color: "#f00"}}>*</span></label>
                            <input type="text" id="cus_name" name="cus_name" className="form-control" onChange={handleData} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6 mb-3'>
                            <label className='form-label'>Teléfono (Opcional)</label>
                            <input type="text" id="cus_phone" name="cus_phone" className="form-control" onChange={handleData} />
                        </div>
                        <div className='col-6 mb-3'>
                            <label className='form-label'>Correo electrónico (Opcional)</label>
                            <input type="text" id="cus_email" name="cus_email" className="form-control" onChange={handleData} />
                        </div>                        
                    </div>
                    <div className='row'>
                        <div className='col-6 mb-3'>
                            <label className='form-label'>Document (Opcional)</label>
                            <input type="text" id="cus_document" name="cus_document" className="form-control" onChange={handleData} />
                        </div>
                        <div className='col-6 mb-3'>
                            <label className='form-label'>Document type (Opcional)</label><br />
                            <select id="cus_document_type" name="cus_document_type" className="form-select" onChange={handleData}>
                                <option key={'option-0'}>Seleccionar</option>
                                {
                                    // eslint-disable-next-line react/prop-types
                                    (Object.keys(props.params).length>0) ?
                                        // eslint-disable-next-line react/prop-types
                                        props.params.documentsType.map((element,index)=>{
                                            return <option key={'option-'+index} value={element.type_id}>{element.type_des}</option>
                                        })
                                    : null
                                }
                            </select>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6 mb-3 form-group'>
                            <label className="mb-2">Fecha de nacimiento</label><br />
                            <DateTimePicker
                                onChange={setManageDate}
                                value={manageDate}
                                format="dd-MM-y"
                                disableClock={true}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* eslint-disable-next-line react/prop-types*/}
                <Button variant="secondary" onClick={()=>{props.handle(false)}}>
                    Cerrar
                </Button>
                {
                    isLoading?
                        <Button variant="primary" disabled>
                            <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    :
                        <Button variant="primary" onClick={save}>
                            Guardar cliente
                        </Button>
                }                
            </Modal.Footer>
        </Modal>
    );
}
export default ModalCustomer;