import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, {useEffect,useState} from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";

const InvoiceAppointment = (props) => {
    const [appointment,setAppointment] = useState(null),
        [services,setServices] = useState([]),
        [isLoading,setIsLoading] = useState(false),
        [closeComment,setCloseComment] = useState(''),
        [serviceSelected,setServiceSelected] = useState(0);
    useEffect(async()=>{
        // eslint-disable-next-line react/prop-types
        if(props.appointment)
        {
            try{
                // eslint-disable-next-line no-undef
                const resource = await axios.post(process.env.REACT_APP_PATH_API + 'appointment',{
                    token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                    // eslint-disable-next-line react/prop-types
                    appointment: props.appointment
                }),
                data = await resource.data;
                setAppointment(data.appointment);
                setServices(data.services);
            } catch(error){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: "Error Interno, favor contacte al administrador"
                });
            }
        }
    // eslint-disable-next-line react/prop-types
    },[props.appointment]);
    const invoicing = async() => {
        setIsLoading(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'appointment/invoicing',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                appointment: appointment.id,
                comment: closeComment
            }),
            response = await resource.data;
            setIsLoading(false);
            if(!response.status){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: response.message
                });
                return;
            }
            Swal.fire({
                icon: 'success',
                title: 'Operación Exitosa',
                text: response.message
            }).then(()=>{
                location.reload();
                // eslint-disable-next-line react/prop-types
                // props.setTurn(response.turns);                
            });
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const addServiceToList = async() => {
        if(serviceSelected<1){
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: "Debes seleccionar al menos un servicio"
            });
            return;
        }
        setIsLoading(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'appointment/new/service',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                appointment: appointment.id,
                serviceId: serviceSelected
            }),
            response = await resource.data;
            setIsLoading(false);
            if(!response.status){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: response.message
                });
                return;
            }
            setServices(response.services);
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    let totalServices = 0;
    return (
        // eslint-disable-next-line react/prop-types
        <Modal show={props.show} backdrop="static" size="lg" onHide={()=>{props.close(false)}}>
            <Modal.Header className='custom-modal-header-color' closeButton>
                <Modal.Title className=''>Cerrar y Facturar Cita</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>CITA</th>
                                        <th>CLIENTE</th>
                                        <th>FECHA</th>
                                        <th>CORREO</th>
                                        <th>TELÉFONO</th>
                                        <th>ESTADO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        appointment!=null?
                                            <tr>
                                                <td>{appointment.id}</td>
                                                <td>{appointment.customer_name}</td>
                                                <td>{appointment.a_date}</td>
                                                <td>{appointment.customer_email}</td>
                                                <td>{appointment.bp_phone}</td>
                                                <td>{appointment.status_des}</td>
                                            </tr>
                                        :null
                                    }                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 form-group mb-3">
                            <label className="mb-2" style={{fontWeight: 'bold'}}>Puedes agregar servicios ó productos adicionales...</label><br />
                            <label className="mb-2">Servicio/Producto</label>
                            <div className="input-group">
                                <select className="form-select" defaultValue={serviceSelected} onChange={(input)=>setServiceSelected(input.currentTarget.value)}>
                                    <option value={0}>Agrega los servicios necesarios a la lista</option>
                                    {
                                        // eslint-disable-next-line react/prop-types
                                        props.mData.products.map((element,idx)=>{
                                            return <option value={element.id} key={idx}>{element.coding+' | '+element.product+' | $'+parseFloat(element.price).toFixed(2)}</option>
                                        })
                                    }
                                </select>
                                {
                                    !isLoading?
                                        <span className="input-group-text btn btn-primary" onClick={addServiceToList}><FontAwesomeIcon icon={faPlusCircle} title="Agregar servicio/producto a la lista" /></span>
                                    :<span className="input-group-text btn btn-primary">
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            />
                                    </span>                                    
                                }
                                {/* eslint-disable-next-line no-undef */}
                                {/* <span className="input-group-text btn btn-primary" onClick={addServiceToList}><FontAwesomeIcon icon={faPlusCircle} title="Agregar servicio/producto a la lista" /></span> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>CÓDIGO</th>
                                        <th>SERVICIO/PRODUCTO</th>
                                        <th>PRECIO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        services.map((element,idx)=>{
                                            totalServices += parseFloat(element.pricing);
                                            return (
                                                <tr key={idx}>
                                                    <td>{element.coding}</td>
                                                    <td>{element.product}</td>
                                                    <td>${parseFloat(element.pricing).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        <tr style={{backgroundColor: '#eee',fontWeight: 'bold'}}>
                                            <td colSpan={2} style={{textAlign: 'right !important'}}>TOTAL: </td>
                                            <td>${parseFloat(totalServices).toFixed(2)}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 form-group">
                            <label>Comentario</label>
                            <textarea 
                                rows={3} 
                                className="form-control" 
                                placeholder="Puede ingresar un comentario"
                                onChange={(input)=>setCloseComment(input.currentTarget.value)}
                                ></textarea>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="row">
                    <div className="col-12" style={{marginTop: '2rem'}}>
                        {/* eslint-disable-next-line react/prop-types */}
                        <Button variant="danger" onClick={()=>{props.close(false)}}>Cerrar</Button>
                        {
                            !isLoading?
                                <Button variant="primary" onClick={invoicing} style={{marginLeft: '1rem'}}>Facturar Cita</Button>
                            :<Button variant="primary" disabled>
                                <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                Loading...
                            </Button>
                        }                        
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default InvoiceAppointment;