import axios from "axios";
import React,{useState} from "react";
import Swal from "sweetalert2";
import { Image, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCalendar, /*faListOl,*/ faPaperclip, faPlus, faRefresh, faUser } from "@fortawesome/free-solid-svg-icons";
const styling = {
    font: {
        fontSize: '9px',
        fontWeight: 'bold',
        textTransform: 'initial'
    },
    eFont: {
        fontSize: '9px',
        fontWeight: 'bold',
        textTransform: 'initial',
        marginLeft: '1rem'
    },
    fontBadge: {
        fontSize: '8px',
        fontWeight: 'bold',
        textTransform: 'initial'
    }
}
const TaskList = (props) => {
    const [isLoading,setLoading] = useState(false),
        [taskIsLoading,setTaskIsLoading] = useState(false);
    const seeTaskEvent = async (input) => {
        let path = 'tasks/by/assign/'+localStorage.getItem('OSHEN-LOC-STR-TK');
        if(input.target.checked){
            //view created
            path = 'tasks/by/crd/'+localStorage.getItem('OSHEN-LOC-STR-TK');
            // eslint-disable-next-line react/prop-types
            props.setViewer(2);
        } else {
            // eslint-disable-next-line react/prop-types
            props.setViewer(1);
        }
        setTaskIsLoading(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.get(process.env.REACT_APP_PATH_API + path),
                data = await resource.data;
            // eslint-disable-next-line react/prop-types
            props.setterInfo({
                // eslint-disable-next-line react/prop-types
                ...props.info,
                tasks: data
            });
            // eslint-disable-next-line react/prop-types
            props.setTaskInfo({});
            setTaskIsLoading(false);
        } catch(error){
            setTaskIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const showTask = async(element) => {
        const task = element.currentTarget.getAttribute('task');
        // eslint-disable-next-line react/prop-types
        props.setLoadingTaskInfo(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.get(process.env.REACT_APP_PATH_API + 'task/'+task+'/'+localStorage.getItem('OSHEN-LOC-STR-TK')),
                data = resource.data;
            // eslint-disable-next-line react/prop-types
            props.setTaskInfo(data.task);
            // eslint-disable-next-line react/prop-types
            props.setUsrList(data.usrS);
            // eslint-disable-next-line react/prop-types
            props.setAllowApprove(data.authApprove);
            // eslint-disable-next-line react/prop-types
            props.setLoadingTaskInfo(false);
        } catch(error){
            // eslint-disable-next-line react/prop-types
            props.setLoadingTaskInfo(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const refreshViewer = async() => {
        setLoading(true);
        setTaskIsLoading(true);
        try{
            let path = 'tasks/by/assign/'+localStorage.getItem('OSHEN-LOC-STR-TK');
            // eslint-disable-next-line react/prop-types
            if(props.viewer==2){
                //view created
                path = 'tasks/by/crd/'+localStorage.getItem('OSHEN-LOC-STR-TK');
            }
            // eslint-disable-next-line no-undef
            const resource = await axios.get(process.env.REACT_APP_PATH_API + path),
            data = await resource.data;
            // eslint-disable-next-line react/prop-types
            props.setterInfo({
                // eslint-disable-next-line react/prop-types
                ...props.info,
                tasks: data
            }); 
            // eslint-disable-next-line react/prop-types
            props.setTaskInfo({});
            setLoading(false);
            setTaskIsLoading(false);
        } catch(error){
            setLoading(false);
            setTaskIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    return(
        <>
        <table className="table task-table">
            <thead>
                <tr>
                    <th style={{...styling.font,...{paddingBottom: '7'}}}>Por hacer</th>
                    <th className="d-flex justify-content-center" style={{paddingBottom: '7'}}>
                        <div className="form-check form-switch" style={{marginBottom: '0'}}>
                            {/* eslint-disable-next-line react/prop-types */}
                            <input className="form-check-input" type="checkbox" role="switch" onChange={seeTaskEvent} />
                            {
                                isLoading?<Spinner animation="border" role="status" size="sm" />
                                :<FontAwesomeIcon icon={faRefresh} style={{cursor: 'pointer'}} onClick={refreshViewer} />
                            }
                        </div>
                    </th>
                    <th style={{...styling.font,...{paddingBottom: '7'}}}>Solicitudes</th>
                </tr>
            </thead>
            <tbody>
                {    
                    taskIsLoading?
                        <tr>
                            <td colSpan={3} style={{textAlign: 'center'}}><Spinner animation="border" role="status" size="sm" variant="primary" /></td>
                        </tr>
                    :                
                    // eslint-disable-next-line react/prop-types
                    (props.tasks!=undefined&&props.tasks.length)?
                        // eslint-disable-next-line react/prop-types
                        props.tasks.map((e,i)=>{
                            return (
                                <tr key={i} task={e.id} style={{cursor: 'pointer'}} onClick={showTask}>
                                    <td style={{width: '20%'}}>
                                        <span style={styling.fontBadge} className={`badge text-bg-${e.status_i}`}>{e.status_des}</span>
                                    </td>
                                    <td style={{width: '60%'}}>
                                        <label style={{...styling.font,...{cursor: 'pointer'}}}>
                                            {
                                                e.files.length?
                                                <FontAwesomeIcon icon={faPaperclip} />
                                                :null
                                            }&nbsp;&nbsp;
                                            {'#'+e.id+' '+e.subject}
                                        </label><br />
                                        <span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faBuilding} />&nbsp;{e.dep}</span>
                                        {/* <span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faListOl} />&nbsp;{e.cond}</span> */}
                                        <span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faCalendar} />&nbsp;{e.createdAtFormatted}</span>
                                        <br /><span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faUser} />&nbsp;{e.personal_name}</span>
                                    </td>
                                    <td style={{width: '20%'}}>
                                        <Image 
                                            style={{width: '42px'}}
                                            fluid={true}
                                            roundedCircle={true}
                                            thumbnail={true}
                                            // eslint-disable-next-line no-undef, react/prop-types
                                            src={(props.viewer==1)?process.env.REACT_APP_PATH_APP+e.path_file_assign:process.env.REACT_APP_PATH_APP+e.path_file}
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    :<tr><td colSpan={3} style={styling.font}>No posees tareas/solicitudes pendientes</td></tr>
                }
            </tbody>            
        </table>
        {
            // eslint-disable-next-line react/prop-types
            props.createPermission?
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            {/* eslint-disable-next-line react/prop-types */}
                            <button type="button" className="btn btn-primary btn-sm" onClick={()=>props.fnTaskModal(true)}><FontAwesomeIcon icon={faPlus} /> Crear tarea</button>
                        </div>
                    </div>
                </div>
            :null
        }        
        </>
    );
}

export default TaskList;