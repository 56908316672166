import React, {useState,useRef} from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
const ModalAccess = (props) => {
    const [loading,setLoading] = useState(false),
        [criteria,setCriteria] = useState(''),
        inputValid = useRef();
    const setValue = (input) => {
        const value = input.currentTarget.value;
        setCriteria(value);
    }
    const valid = async() => {
        setLoading(true);
        try{
            if(criteria.length<1){
                Swal.fire({
                    icon:'warning',
                    title: 'Advertencia',
                    text: 'Debe ingresar al menos un codigo'
                });
                setLoading(false);
                return;
            }
            // eslint-disable-next-line no-undef
            const response = await axios.post(process.env.REACT_APP_PATH_API + 'check/access/permission',{token: localStorage.getItem('OSHEN-LOC-STR-TK'),criteria:criteria}),
                data = response.data;
            setLoading(false);
            if(!data.status){
                // eslint-disable-next-line react/prop-types
                props.setAccessAllow(false);
                Swal.fire({
                    icon:'warning',
                    title: 'Advertencia',
                    text: data.message
                });
                return;
            }
            // eslint-disable-next-line react/prop-types
            props.setAccessAllow(true);
            // eslint-disable-next-line react/prop-types
            props.handle(false);
            setCriteria('');
            inputValid.current.value = "";
        } catch(error){
            setLoading(false);
            Swal.fire({
                icon:'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    return(
        // eslint-disable-next-line react/prop-types
        <Modal size="sm" show={props.show} onHide={()=>props.handle(false)} backdrop="static" keyboard={false}>
            <Modal.Header className="custom-modal-header" closeButton>
                <Modal.Title>Control de acceso</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding: 0}}>
                <div className="container-fluid" style={{padding: 0}}>
                    <div className="row">
                        <div className="col-12">
                            <input                                 
                                type="password" 
                                className="form-control"
                                ref={inputValid}
                                maxLength={4}
                                autoFocus={true}
                                style={{height: '5rem',fontSize: '42px',paddingTop: 0,paddingBottom: 0,paddingLeft: 5,paddingRight: 0}}
                                placeholder="Código"
                                onChange={setValue}
                                onKeyDown={(e)=>{
                                    if(e.key==='Enter'){
                                        valid();
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* eslint-disable-next-line react/prop-types */}
                <Button variant="secondary" onClick={()=>props.handle(false)}>
                    Close
                </Button>
                {
                    !loading?<Button variant="primary" onClick={valid}>Procesar</Button>
                    :<Button variant="primary" disabled>
                        <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                        Loading...
                    </Button>  
                }                
            </Modal.Footer>
        </Modal>
    );
}

export default ModalAccess;