import React,{useState,useEffect} from "react";
import {Modal} from 'react-bootstrap';
import { Button,Spinner } from "react-bootstrap";
import Swal from 'sweetalert2';
import axios from 'axios';
const defaultDenom = {
    dosMil: 0,
    mil: 0,
    quinientos: 0,
    dosCientos: 0,
    cien: 0,
    cincuenta: 0,
    veinteYCinco: 0,
    diez: 0,
    cinco: 0,
    uno: 0
}
const ModalBb = (props) => {
    const [currency,setCurrency] = useState(0),
        [amount,setAmount] = useState(0),
        [terminalId,setTerminalId] = useState(0),
        [note,setNote] = useState(''),
        [loading,setLoading] = useState(false),
        [denomTurn, setDenomTurn] = useState(defaultDenom);
    useEffect(()=>{
        let amountCalc = 
            ( 2000 * denomTurn.dosMil ) +
            ( 1000 * denomTurn.mil ) +
            ( 500 * denomTurn.quinientos ) + 
            ( 200 * denomTurn.dosCientos ) + 
            ( 100 * denomTurn.cien ) + 
            ( 50 * denomTurn.cincuenta ) + 
            ( 25 * denomTurn.veinteYCinco ) +
            ( 10 * denomTurn.diez ) + 
            ( 5 * denomTurn.cinco ) +
            ( 1 * denomTurn.uno )
        ;
        setAmount(amountCalc);
    },[denomTurn]);
    useEffect(()=>{
        // eslint-disable-next-line react/prop-types
        if(props.datax.currencies!=null&&props.datax.currencies!=undefined){
            // eslint-disable-next-line react/prop-types
            setCurrency(props.datax.currencies[0].currency_id);
        }
        // eslint-disable-next-line react/prop-types
        if(props.datax.terminalId!=null){
            // eslint-disable-next-line react/prop-types
            setTerminalId(props.datax.terminalId);
        }
    // eslint-disable-next-line react/prop-types
    },[props.datax]);
    const changeCurrency = (input) => {
        const id = input.target.value;
        setCurrency(id);
    }
    // const changeAmount = (input) => {
    //     const amount = input.target.value;
    //     setAmount(amount);
    // }
    const changeNote = (input) => {
        const note = input.target.value;
        setNote(note);
    }
    const saveConfig = async() => {
        setLoading(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'save/business/box/config',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                terminal: terminalId,
                currency: currency,
                amount: amount,
                note: note,
                denomTurn: denomTurn
            }),
            data = await resource.data;
            if(data.status){
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Éxito',
                    text: data.msg
                }).then(()=>{
                    // eslint-disable-next-line react/prop-types
                    props.handle(false);
                    // eslint-disable-next-line react/prop-types
                    props.bbOpen(true);
                });
            } else {
                setLoading(false);
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
            }
        } catch(error){
            setLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const handleDenom = (input) => {
        const id = input.target.id,
            value = (input.target.value) ? input.target.value : 0;
        setDenomTurn({
            ...denomTurn,
            [id]: value
        });            
    }
    return (
        // eslint-disable-next-line react/prop-types
        <Modal show={props.show} onHide={()=>{props.handle(false)}}>
            <Modal.Header className='custom-modal-header-color' closeButton>
                <Modal.Title className=''>Abrir turno</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row">
                        {/* eslint-disable-next-line react/prop-types */}
                        <div className='col-12 mb-3'><p>Terminal: {(props.datax.terminalId!=null)?props.datax.terminalLabel:'No hay terminal asignada'}</p></div>
                    </div>
                    <div className="row">
                        <h6>Favor especificar la cantidad de cada denominación</h6>
                        <div className="col-12 table-responsive">
                            <table className="table table-condesed">
                                <tbody>
                                    <tr>
                                        <td>$2,000.00</td>
                                        <td><input type="number" className="form-control" id="dosMil" onChange={handleDenom} /></td>
                                        <td>$1,000.00</td>
                                        <td><input type="number" className="form-control" id="mil" onChange={handleDenom} /></td>
                                    </tr>
                                    <tr>
                                        <td>$500.00</td>
                                        <td><input type="number" className="form-control" id="quinientos" onChange={handleDenom} /></td>
                                        <td>$200.00</td>
                                        <td><input type="number" className="form-control" id="dosCientos" onChange={handleDenom} /></td>
                                    </tr>
                                    <tr>
                                        <td>$100.00</td>
                                        <td><input type="number" className="form-control" id="cien" onChange={handleDenom} /></td>
                                        <td>$50.00</td>
                                        <td><input type="number" className="form-control" id="cincuenta" onChange={handleDenom} /></td>
                                    </tr>
                                    <tr>
                                        <td>$25.00</td>
                                        <td><input type="number" className="form-control" id="veinteYCinco" onChange={handleDenom} /></td>
                                        <td>$10.00</td>
                                        <td><input type="number" className="form-control" id="diez" onChange={handleDenom} /></td>
                                    </tr>
                                    <tr>
                                        <td>$5.00</td>
                                        <td><input type="number" className="form-control" id="cinco" onChange={handleDenom} /></td>
                                        <td>$1.00</td>
                                        <td><input type="number" className="form-control" id="uno" onChange={handleDenom} /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>                    
                    <div className="row">
                        <div className='col-4 mb-3'>
                            <label className='form-label'>Monto inicial <span style={{color: "#f00"}}>*</span></label><br />
                            <input type="number" className="form-control" value={amount} /*onChange={changeAmount}*/ readOnly={true} />
                        </div>
                        <div className='col-4 mb-3'>
                            <label className='form-label'>Moneda <span style={{color: "#f00"}}>*</span></label><br />
                            <select keyid="currency" className="form-select" onChange={changeCurrency} value={currency} disabled>
                                {
                                    // eslint-disable-next-line react/prop-types
                                    (Object.keys(props.datax).length>0) ?
                                        // eslint-disable-next-line react/prop-types
                                        props.datax.currencies.map((element,index)=>{
                                            return <option key={'option-'+index} value={element.currency_id}>{element.currency_ref}</option>
                                        })
                                    : null
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <label className='form-label'>Nota</label>
                            <textarea keyid="note" className="form-control" onChange={changeNote}></textarea>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* eslint-disable-next-line react/prop-types */}
                <Button variant="secondary" onClick={()=>{props.handle(false)}}>
                    Cerrar
                </Button>
                {
                    loading?
                    <Button variant="success" disabled>
                        <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                        Loading...
                    </Button>
                    :
                    <Button variant="success" onClick={saveConfig}>
                        Guardar
                    </Button>
                }                
            </Modal.Footer>
        </Modal>
    );
}
export default ModalBb;